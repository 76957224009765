import * as React from 'react';
import { api } from '../../../API';
import { Search } from './Search';

export class OrderSearch extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      orders: null,
      venues: null,
      loading: true,
      loadingResults: true,
      page: 1,
      perpage: 25,
      query: {},
      refundOrder: null,
      detailOrder: null,
    };

    this.fetch = this.fetch.bind(this);
    this.onChangeQuery = this.onChangeQuery.bind(this);
    this.onClickNext = this.onClickNext.bind(this);
    this.onClickPrevious = this.onClickPrevious.bind(this);
    this.onClickRefund = this.onClickRefund.bind(this);
    this.confirmRefund = this.confirmRefund.bind(this);
    this.rejectRefund = this.rejectRefund.bind(this);
  }

  componentDidMount() {
    this.fetch();
  }

  async fetch() {
    return Promise.all([this.fetchVenue(), this.search()]).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  async fetchVenue() {
    const venues = await api.getActiveVenues();
    this.setState({ venues });
  }

  async search() {
    this.setState({ loadingResults: true });

    const query = Object.assign(
      {
        page: this.state.page,
        perpage: this.state.perpage,
      },
      this.state.query
    );

    const orders = await api.searchOrders(query);
    this.setState({ loadingResults: false, orders });
  }

  onClickNext() {
    this.setState({ page: this.state.page + 1 }, () => {
      this.search();
    });
  }

  onClickPrevious() {
    this.setState({ page: this.state.page - 1 }, () => {
      this.search();
    });
  }

  onClickRefund(order) {
    this.setState({ refundOrder: order });
  }

  async confirmRefund({ formData }) {
    this.setState({ refundOrder: null, loadingResults: true });
    await api.refundOrder(this.state.refundOrder.orderId, formData);
    await this.search();
  }

  rejectRefund() {
    this.setState({ refundOrder: null });
  }

  onClickEdit = (order) => {
    this.props.history.push('/orders/update/' + order.orderId);
  };

  onChangeQuery(query) {
    this.setState({ query }, () => {
      this.search();
    });
  }

  viewOrder = async (order) => {
    const detailOrder = await api.getOrder(order.orderId);
    this.setState({ detailOrder });
  };

  clearDetailOrder = () => {
    this.setState({ detailOrder: null });
  };

  render() {
    if (this.state.loading) {
      return <div>Loading...</div>;
    }

    return (
      <Search
        onClickViewOrder={this.viewOrder}
        venues={this.state.venues}
        onChangeQuery={this.onChangeQuery}
        loading={this.state.loadingResults}
        orders={this.state.orders}
        onClickNext={this.state.orders.length < this.state.perpage ? null : this.onClickNext}
        onClickPrevious={this.state.page === 1 ? null : this.onClickPrevious}
        onClickRefund={this.onClickRefund}
        confirmRefund={this.confirmRefund}
        rejectRefund={this.rejectRefund}
        refundOrder={this.state.refundOrder}
        onClickEdit={this.onClickEdit}
        clearDetailOrder={this.clearDetailOrder}
        detailOrder={this.state.detailOrder}
      />
    );
  }
}
