import { preorderReminder as schema } from '@getnoble/noble-json-schemas';
import { Form } from '../../components';
import * as React from 'react';
import { Alert } from 'reactstrap';
import { api } from '../../API';

export class SendPreorderReminder extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = { loading: false, saved: false };
  }

  handleSubmit = async ({ formData }) => {
    this.setState({ loading: true });

    await api.sendPreorderReminders(formData);

    this.setState({ saved: true, loading: false });
  };

  render() {
    if (this.state.loading) {
      return <div>Loading...</div>;
    }

    const uiSchema = {
      venueId: {
        'ui:widget': 'entity',
        'ui:options': {
          label: true,
          load: api.getActiveVenues,
          process: (venues) => venues.map((venue) => ({ value: venue.id, name: venue.name })),
        },
      },
    };

    const savedAlert = this.state.saved && <Alert color='primary'>Sent</Alert>;

    return (
      <div className='w-100'>
        {savedAlert}
        <Form uiSchema={uiSchema} schema={schema} onSubmit={this.handleSubmit} />
      </div>
    );
  }
}
