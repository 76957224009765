import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { api } from '../../API';
import { PickupLocationForm } from './Form';

interface AddPickupLocationRouterParams {
  venueId: string;
  fulfillmentCenterId: string;
}

export class AddPickupLocation extends React.Component<RouteComponentProps<AddPickupLocationRouterParams>, any> {
  handleSubmit = async (data) => {
    const { venueId, fulfillmentCenterId } = this.props.match.params;
    await api.createPickupLocation(venueId, fulfillmentCenterId, data);
    this.props.history.push(`/venues/${venueId}/fulfillment-centers/${fulfillmentCenterId}/pickup-locations/list`);
  };

  render() {
    return <PickupLocationForm onSubmit={this.handleSubmit} />;
  }
}
