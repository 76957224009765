import * as React from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { api } from '../../API';
import { DataTable } from '../../components/DataTable';
import { format } from 'date-fns';
import { RouteComponentProps } from 'react-router';

type Props = RouteComponentProps<{
  integrationStackId?: string;
}>;

export class SyncConfigList extends React.Component<Props, any> {
  constructor(props) {
    super(props);

    this.state = { rejectReasons: [], loading: true };
  }

  fetch = async () => {
    const rejectReasons = await api.getSyncConfigs();
    this.setState({
      loading: false,
      rejectReasons,
    });
  };

  syncingConfigs = async (id) => {
    const rejectReasons = await api.fetchSyncingConfigsForIntegrationStack(id);
    this.setState({
      loading: false,
      rejectReasons,
    });
  };

  queueSyncConfig = async (data) => {
    this.setState({
      loading: true,
    });
    await api.queueSyncConfig(data.id);
    this.setState({
      loading: false,
    });
  };

  componentDidMount() {
    const { integrationStackId } = this.props.match.params;
    integrationStackId ? this.syncingConfigs(integrationStackId) : this.fetch();
  }

  render() {
    if (this.state.loading) {
      return <div>Loading...</div>;
    } else {
      const { rejectReasons } = this.state;

      return (
        <Card className='w-100'>
          <CardHeader>Sync Config List</CardHeader>
          <CardBody>
            <DataTable
              headers={['ID', 'Name', 'Sync Frequency (mins)', 'Last Successful Sync', 'Is Active', 'Actions']}
              rows={rejectReasons.map((syncConfig) => [
                syncConfig.id,
                syncConfig.name,
                syncConfig.syncFrequencyMinutes,
                syncConfig.lastSuccessfulSync
                  ? format(new Date(syncConfig.lastSuccessfulSync), 'MMMM d, yyyy h:mm:ss a')
                  : '',
                syncConfig.isActive ? 'Yes' : 'No',
                <div key='actions'>
                  <Button tag='a' href={`/sync-config/edit/${syncConfig.id}`} color='success'>
                    Edit
                  </Button>
                  <Button
                    tag='a'
                    style={{ marginLeft: 10 + 'px' }}
                    onClick={() => this.queueSyncConfig(syncConfig)}
                    color='success'
                  >
                    Trigger Sync
                  </Button>
                  <Button
                    tag='a'
                    style={{ marginLeft: 10 + 'px' }}
                    href={`/sync-Record/list/${syncConfig.id}`}
                    color='success'
                  >
                    View Syncing Results
                  </Button>
                </div>,
              ])}
            />
            <div className='text-center'>
              <Button tag='a' href={`/sync-config/add`} color='success'>
                Add
              </Button>
            </div>
          </CardBody>
        </Card>
      );
    }
  }
}
