import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { api } from '../../API';
import { SyncConfigForm } from './Form';

export class AddSyncConfig extends React.Component<RouteComponentProps, any> {
  handleSubmit = async (data) => {
    await api.createSyncConfig(data.integrationStackId, data);
    this.props.history.push('/sync-config/list');
  };

  render() {
    return <SyncConfigForm onSubmit={this.handleSubmit} />;
  }
}
