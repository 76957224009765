/* tslint:disable:max-line-length */

import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Layout } from './components/Layout';
import {
  DeliveryLocationsRoutes,
  BrandSplitPaymentMethodsRoutes,
  DrinkOptionRoutes,
  DrinkRoutes,
  DeliveryConfigurationRoutes,
  FeatureRoutes,
  EventRoutes,
  FreedomPayConfigRoutes,
  FulfillmentCentersRoutes,
  LoginRoutes,
  MenuStyleRoutes,
  MenuTemplateRoutes,
  MFAInput,
  OrderRoutes,
  OptionGroupRoutes,
  PickupLocationRoutes,
  PreorderReminderRoutes,
  PromoCodeRoutes,
  PartnerRoutes,
  RejectReasonRoutes,
  ProductUpdateRoutes,
  RoleRoutes,
  SessionRoutes,
  TextmunicationConfigurationRoutes,
  UserRoutes,
  VenueInvite,
  VenueOperatorRoutes,
  VenueRoutes,
  PosRoutes,
  PreorderReminderEmailRoutes,
  PreorderReminderEmailListRoutes,
  PreorderReminderListRoutes,
  IntegrationStackRoutes,
  SyncConfigRoutes,
  SyncRecordsRoutes,
  TimeSlotRoutes,
} from './routes';
import { PrivateRoute } from './PrivateRoute';
import { paths } from './paths';

export class App extends React.PureComponent<any, any> {
  componentDidCatch(error) {
    alert(error);
  }

  render() {
    // <Route exact={true} path='/venues/:venueId/fulfillment-centers/:fulfillmentCenterId/delivery-locations/add' component={DeliveryLocationsRoutes.AddCategory} />
    return (
      <Switch>
        <Route exact={true} path='/' component={LoginRoutes.Login} />
        <Route exact={true} path='/mfa' component={MFAInput} />
        <Layout>
          <React.Suspense fallback={<div>Loading...</div>}>
            <PrivateRoute exact={true} path='/venues/health/:id' component={VenueRoutes.AsyncVenueHealth} />
            <PrivateRoute exact={true} path={paths.venues.edit.pattern} component={VenueRoutes.AsyncEditVenue} />
            <PrivateRoute exact={true} path={paths.venues.add.pattern} component={VenueRoutes.AsyncCreateVenue} />
            <PrivateRoute exact={true} path='/venues/edit/config/:id' component={VenueRoutes.AsyncEditConfig} />
            <PrivateRoute path='/venues/:venueId/setup-pos' component={PosRoutes.AsyncSetupPosStart} exact={true} />
            <PrivateRoute
              path={paths.setupVerifoneDevice.pattern}
              component={PosRoutes.AsyncVerifoneDeviceSetup}
              exact={true}
            />

            <PrivateRoute
              path='/venues/:venueId/setup-pos/toast'
              component={PosRoutes.AsyncToastPosConfig}
              exact={true}
            />

            <PrivateRoute path={paths.events.pattern} component={EventRoutes.AsyncEvents} exact={true} />

            <PrivateRoute path={paths.posOrders.pattern} component={PosRoutes.AsyncPOSOrders} exact={true} />
            <PrivateRoute path={paths.posConfig.pattern} component={PosRoutes.AsyncPOSConfig} exact={true} />

            <PrivateRoute path={paths.partners.list.pattern} component={PartnerRoutes.AsyncPartnerList} exact={true} />
            <PrivateRoute path={paths.partners.add.pattern} component={PartnerRoutes.AsyncAddPartner} exact={true} />
            {/* <PrivateRoute
              path={paths.partners.edit.pattern}
              component={DeliveryConfigurationRoutes.AsyncEditDeliveryConfigurations}
              exact={true}
            /> */}

            <PrivateRoute
              path={paths.timeSlots.listForFulfillmentCenter.pattern}
              component={TimeSlotRoutes.AsyncTimeSlotList}
              exact={true}
            />
            <PrivateRoute
              path={paths.timeSlots.listForVenue.pattern}
              component={TimeSlotRoutes.AsyncTimeSlotList}
              exact={true}
            />
            <PrivateRoute
              path={paths.timeSlots.create.pattern}
              component={TimeSlotRoutes.AsyncAddTimeSlot}
              exact={true}
            />
            <PrivateRoute
              path={paths.timeSlots.update.pattern}
              component={TimeSlotRoutes.AsyncEditTimeSlot}
              exact={true}
            />
            <PrivateRoute
              path={paths.partnerLocationGroup.list.pattern}
              component={PartnerRoutes.AsyncPartnerLocationGroupList}
              exact={true}
            />
            <PrivateRoute
              path={paths.partnerLocationGroup.add.pattern}
              component={PartnerRoutes.AsyncAddPartnerLocationGroup}
              exact={true}
            />
            <PrivateRoute
              path={paths.partnerLocationGroup.edit.pattern}
              component={PartnerRoutes.AsyncEditPartnerLocationGroup}
              exact={true}
            />

            <PrivateRoute
              path={paths.partnerLocation.list.pattern}
              component={PartnerRoutes.AsyncPartnerLocationList}
              exact={true}
            />
            <PrivateRoute
              path={paths.partnerLocation.add.pattern}
              component={PartnerRoutes.AsyncAddPartnerLocation}
              exact={true}
            />
            <PrivateRoute
              path={paths.partnerLocation.viewMenu.pattern}
              component={PartnerRoutes.AsyncViewPartnerLocationMenu}
              exact={true}
            />

            <PrivateRoute
              path={paths.deliveryConfigurations.list.pattern}
              component={DeliveryConfigurationRoutes.AsyncListDeliveryConfigurations}
              exact={true}
            />
            <PrivateRoute
              path={paths.deliveryConfigurations.add.pattern}
              component={DeliveryConfigurationRoutes.AsyncAddDeliveryConfigurations}
              exact={true}
            />
            <PrivateRoute
              path={paths.deliveryConfigurations.edit.pattern}
              component={DeliveryConfigurationRoutes.AsyncEditDeliveryConfigurations}
              exact={true}
            />

            <PrivateRoute exact={true} path='/option-groups' component={OptionGroupRoutes.AsyncOptionGroups} />
            <PrivateRoute
              exact={true}
              path='/option-groups/edit/:id'
              component={OptionGroupRoutes.AsyncEditOptionGroup}
            />
            <PrivateRoute exact={true} path='/option-groups/add' component={OptionGroupRoutes.AsyncAddOptionGroup} />

            <PrivateRoute
              exact={true}
              path='/venues/:venueId/fulfillment-centers/:fulfillmentCenterId/delivery-locations'
              component={DeliveryLocationsRoutes.AsyncListLocations}
            />
            <PrivateRoute
              exact={true}
              path='/venues/:venueId/fulfillment-centers/:fulfillmentCenterId/delivery-locations/:parentCategoryId/:locationId'
              component={DeliveryLocationsRoutes.AsyncListLocations}
            />
            <PrivateRoute
              exact={true}
              path={paths.fulfillmentCenters.markAsSoldOut.pattern}
              component={FulfillmentCentersRoutes.AsyncFulfillmentCenterMarkItemAsSoldout}
            />

            <PrivateRoute
              exact={true}
              path='/venues/:venueId/fulfillment-centers/:fulfillmentCenterId/delivery-locations/add/:categoryId'
              component={DeliveryLocationsRoutes.AsyncEditCategory}
            />

            <PrivateRoute
              exact={true}
              path='/venues/:venueId/fulfillment-centers/:fulfillmentCenterId/pickup-locations/add'
              component={PickupLocationRoutes.AddPickupLocation}
            />
            <PrivateRoute
              exact={true}
              path='/venues/:venueId/fulfillment-centers/:fulfillmentCenterId/pickup-locations/edit/:id'
              component={PickupLocationRoutes.EditPickupLocation}
            />
            <PrivateRoute
              exact={true}
              path='/venues/:venueId/fulfillment-centers/:fulfillmentCenterId/pickup-locations/list'
              component={PickupLocationRoutes.PickupLocations}
            />
            <PrivateRoute exact={true} path='/create-braintree-merchant' component={VenueRoutes.AsyncBraintreeForm} />
            <PrivateRoute exact={true} path='/braintree-status' component={VenueRoutes.AsyncBraintreeStatus} />

            <PrivateRoute exact={true} path='/home' component={VenueOperatorRoutes.AsyncVenueOperators} />
            <PrivateRoute
              exact={true}
              path='/venue-operators/add'
              component={VenueOperatorRoutes.AsyncAddVenueOperator}
            />
            <PrivateRoute
              exact={true}
              path='/venue-operators/edit/:id'
              component={VenueOperatorRoutes.AsyncEditVenueOperator}
            />
            <PrivateRoute
              exact={true}
              path='/venue-operators/venues/:id'
              component={VenueRoutes.AsyncVenuesContainer}
            />

            <PrivateRoute exact={true} path='/menu-styles' component={MenuStyleRoutes.AsyncMenuStylesList} />
            <PrivateRoute exact={true} path='/menu-styles/add' component={MenuStyleRoutes.AsyncAddMenuStyle} />
            <PrivateRoute exact={true} path='/menu-styles/edit/:id' component={MenuStyleRoutes.AsyncEditMenuStyle} />

            <PrivateRoute
              exact={true}
              path='/venues/:venueId/fulfillment-centers/add'
              component={FulfillmentCentersRoutes.AsyncAddFulfillmentCenter}
            />
            <PrivateRoute
              exact={true}
              path={paths.fulfillmentCenters.edit.pattern}
              component={FulfillmentCentersRoutes.AsyncEditFulfillmentCenter}
            />
            <PrivateRoute
              exact={true}
              path='/venues/:venueId/fulfillment-centers/:id/test-delivery/'
              component={FulfillmentCentersRoutes.AsyncTestDeliveryFormat}
            />

            <PrivateRoute
              exact={true}
              path='/textmunication-configs/add'
              component={TextmunicationConfigurationRoutes.AsyncAddTextmunicationConfig}
            />
            <PrivateRoute
              exact={true}
              path='/textmunication-configs/edit/:id'
              component={TextmunicationConfigurationRoutes.AsyncEditTextmunicationConfig}
            />
            <PrivateRoute
              exact={true}
              path='/textmunication-configs/list'
              component={TextmunicationConfigurationRoutes.AsyncTextmunicationConfigList}
            />

            <PrivateRoute
              exact={true}
              path='/venues/:venueId/fulfillment-centers/:fulfillmentCenterId/session'
              component={SessionRoutes.EditSession}
            />

            <PrivateRoute
              exact={true}
              path='/venues/:venueId/fulfillment-centers/:id/menu'
              component={FulfillmentCentersRoutes.AsyncFulfillmentCenterMenu}
            />

            <PrivateRoute exact={true} path='/venue-invite' component={VenueInvite} />

            <PrivateRoute exact={true} path='/users/edit/:id' component={UserRoutes.EditUser} />
            <PrivateRoute exact={true} path='/users/invite' component={UserRoutes.Invite} />
            <PrivateRoute exact={true} path='/users/search' component={UserRoutes.Search} />

            <PrivateRoute
              exact={true}
              path='/sessions/:venueId/:fulfillmentCenterId/:sessionId/last-report'
              component={SessionRoutes.FinalReport}
            />
            <PrivateRoute
              exact={true}
              path='/sessions/:venueId/:fulfillmentCenterId'
              component={SessionRoutes.SessionsList}
            />

            <PrivateRoute
              exact={true}
              path='/freedom-pay-configs/add'
              component={FreedomPayConfigRoutes.AsyncAddFreedomPayConfig}
            />
            <PrivateRoute
              exact={true}
              path='/freedom-pay-configs/edit/:id'
              component={FreedomPayConfigRoutes.AsyncEditFreedomPayConfig}
            />
            <PrivateRoute
              exact={true}
              path='/freedom-pay-configs/list'
              component={FreedomPayConfigRoutes.AsyncFreedomPayConfigList}
            />

            <PrivateRoute
              exact={true}
              path='/brand-split-payment-method/add'
              component={BrandSplitPaymentMethodsRoutes.AsyncAddBrandSplitPaymentMethod}
            />
            <PrivateRoute
              exact={true}
              path='/brand-split-payment-method/edit/:id'
              component={BrandSplitPaymentMethodsRoutes.AsyncEditBrandSplitPaymentMethod}
            />
            <PrivateRoute
              exact={true}
              path='/brand-split-payment-method/list'
              component={BrandSplitPaymentMethodsRoutes.AsyncBrandSplitPaymentMethodsList}
            />

            <PrivateRoute exact={true} path='/reject-reasons/add' component={RejectReasonRoutes.AddRejectReason} />
            <PrivateRoute
              exact={true}
              path='/reject-reasons/edit/:id'
              component={RejectReasonRoutes.EditRejectReason}
            />
            <PrivateRoute exact={true} path='/reject-reasons/list' component={RejectReasonRoutes.RejectReasonsList} />

            <PrivateRoute
              exact={true}
              path='/integration-stack/add'
              component={IntegrationStackRoutes.AddIntegrationStack}
            />
            <PrivateRoute
              exact={true}
              path='/integration-stack/edit/:id'
              component={IntegrationStackRoutes.EditIntegrationStack}
            />
            <PrivateRoute
              exact={true}
              path={paths.posv2.integrations.list.pattern}
              component={IntegrationStackRoutes.IntegrationStackList}
            />

            <PrivateRoute exact={true} path='/sync-config/add' component={SyncConfigRoutes.AddSyncConfig} />
            <PrivateRoute exact={true} path='/sync-config/edit/:id' component={SyncConfigRoutes.EditSyncConfig} />
            <PrivateRoute
              exact={true}
              path={paths.posv2.integrations.syncingConfigs.pattern}
              component={SyncConfigRoutes.SyncConfigList}
            />
            <PrivateRoute exact={true} path='/sync-config/list' component={SyncConfigRoutes.SyncConfigList} />

            <PrivateRoute exact={true} path='/sync-Record/list/:id' component={SyncRecordsRoutes.SyncRecordsList} />

            <PrivateRoute
              exact={true}
              path={paths.venuePosLanding.pattern}
              component={PosRoutes.AsyncVenuePOSLanding}
            />

            <PrivateRoute
              exact={true}
              path={paths.posv2.cloverFlex.add.pattern}
              component={PosRoutes.AsyncCloverFlexAdd}
            />
            <PrivateRoute
              exact={true}
              path={paths.posv2.cloverFlex.edit.pattern}
              component={PosRoutes.AsyncCloverFlexEdit}
            />

            <PrivateRoute
              exact={true}
              path={paths.posv2.cloverSport.add.pattern}
              component={PosRoutes.AsyncCloverSportAdd}
            />
            <PrivateRoute
              exact={true}
              path={paths.posv2.cloverSport.editForVenue.pattern}
              component={PosRoutes.AsyncCloverSportEdit}
            />
            <PrivateRoute
              exact={true}
              path={paths.posv2.cloverSport.edit.pattern}
              component={PosRoutes.AsyncCloverSportEdit}
            />

            <PrivateRoute exact={true} path='/roles/add' component={RoleRoutes.AddRole} />
            <PrivateRoute exact={true} path='/roles/edit/:id' component={RoleRoutes.EditRole} />
            <PrivateRoute exact={true} path='/roles/list' component={RoleRoutes.RoleList} />

            <PrivateRoute exact={true} path='/promo-codes/add' component={PromoCodeRoutes.AddCode} />
            <PrivateRoute exact={true} path='/promo-codes/add/user/:userId' component={PromoCodeRoutes.AddCode} />
            <PrivateRoute exact={true} path='/promo-codes/venue/:venueId' component={PromoCodeRoutes.AddCode} />
            <PrivateRoute exact={true} path='/promo-codes/edit/:id' component={PromoCodeRoutes.EditCode} />
            <PrivateRoute exact={true} path='/promo-codes/list' component={PromoCodeRoutes.PromoCodesList} />

            <PrivateRoute
              exact={true}
              path='/preorder-reminder'
              component={PreorderReminderRoutes.SendPreorderReminder}
            />
            <PrivateRoute
              exact={true}
              path='/preorder-reminder-list'
              component={PreorderReminderListRoutes.PreorderReminderList}
            />
            <PrivateRoute
              exact={true}
              path='/preorder-reminder-emails'
              component={PreorderReminderEmailRoutes.SendPreorderReminder}
            />
            <PrivateRoute
              exact={true}
              path='/preorder-reminder-emails-response'
              component={PreorderReminderEmailListRoutes.PreorderReminderEmailList}
            />

            <PrivateRoute
              exact={true}
              path='/orders/venue/:venueId/fulfillment-center/:fulfillmentCenterId'
              component={OrderRoutes.OrdersList}
            />
            <PrivateRoute exact={true} path='/orders/search' component={OrderRoutes.OrderSearch} />
            <PrivateRoute exact={true} path='/orders/change-user' component={OrderRoutes.ChangeOrderUser} />
            <PrivateRoute exact={true} path='/orders/update/:orderId' component={OrderRoutes.UpdateOrder} />
            <PrivateRoute exact={true} path='/orders/confirm' component={OrderRoutes.ConfirmOrder} />
            <PrivateRoute
              exact={true}
              path={paths.orders.resendReceipt.pattern}
              component={OrderRoutes.AsyncResendReceipt}
            />
            <PrivateRoute
              exact={true}
              path={paths.venues.manageOrder.pattern}
              component={OrderRoutes.AsyncManageIndividualOrder}
            />

            <PrivateRoute
              exact={true}
              path='/menu-templates/list'
              component={MenuTemplateRoutes.AsyncMenuTemplatesList}
            />
            <PrivateRoute exact={true} path='/menu-templates/add' component={MenuTemplateRoutes.AsyncAddMenuTemplate} />
            <PrivateRoute exact={true} path='/menu/:menuId' component={MenuTemplateRoutes.AsyncMenuItemList} />

            <PrivateRoute exact={true} path='/drinks' component={DrinkRoutes.AsyncDrinkList} />
            <PrivateRoute exact={true} path='/drinks/:categoryId/add' component={DrinkRoutes.AsyncAddDrink} />
            <PrivateRoute exact={true} path='/drinks/:id' component={DrinkRoutes.AsyncEditDrink} />
            <PrivateRoute exact={true} path='/drinks/:id/merge' component={DrinkRoutes.AsyncMergeDrinks} />
            <PrivateRoute exact={true} path='/drinks/shared/:menuId' component={DrinkRoutes.AsyncSharedDrinks} />

            <PrivateRoute exact={true} path='/drink-options' component={DrinkOptionRoutes.AsyncDrinkOptions} />
            <PrivateRoute
              exact={true}
              path='/drink-options/:type/add'
              component={DrinkOptionRoutes.AsyncAddDrinkOption}
            />
            <PrivateRoute exact={true} path='/drink-options/:id' component={DrinkOptionRoutes.AsyncEditDrinkOption} />

            <PrivateRoute exact={true} path='/features' component={FeatureRoutes.AsyncFeaturesList} />
            <PrivateRoute exact={true} path='/features/add' component={FeatureRoutes.AsyncAddFeature} />

            <PrivateRoute exact={true} path='/product-updates' component={ProductUpdateRoutes.ProductUpdateList} />
            <PrivateRoute exact={true} path='/product-updates/add' component={ProductUpdateRoutes.AddProductUpdate} />
            <PrivateRoute
              exact={true}
              path='/product-updates/edit/:id'
              component={ProductUpdateRoutes.EditProductUpdate}
            />
          </React.Suspense>
        </Layout>
      </Switch>
    );
  }
}
