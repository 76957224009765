import { Card, CardBody, CardHeader } from 'reactstrap';
import * as React from 'react';
import { Form } from '../../components';
import { api } from '../../API';
import { JSONSchema7 } from 'json-schema';

const schema: JSONSchema7 = {
  properties: {
    orderId: {
      type: 'integer',
      title: 'Order Id',
    },
    userId: {
      type: 'string',
      title: 'User Id',
    },
  },
  required: ['orderId', 'userId'],
  type: 'object',
};

export class ChangeOrderUser extends React.Component<{}, { loading: boolean; updated: boolean }> {
  constructor(props) {
    super(props);

    this.state = { loading: false, updated: false };
  }

  onSubmit = async (data) => {
    this.setState({ loading: true });
    await api.changeOrderUser(data.formData.orderId, data.formData.userId);
    this.setState({ loading: false, updated: true });
  };

  render() {
    return (
      <Card>
        <CardHeader>Add Feature</CardHeader>
        <CardBody>
          {this.state.updated && <div className='text-danger'>Saved</div>}
          <Form schema={schema} onSubmit={this.onSubmit} />
        </CardBody>
      </Card>
    );
  }
}
