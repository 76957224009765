import { Loadable } from '../../Loadable';

export const AsyncSetupPosStart = Loadable(() => import('./SetupPosStart'));
export const AsyncToastPosConfig = Loadable(() => import('./ToastPosConfig'));
export const AsyncPOSOrders = Loadable(() => import('./POSOrders'));
export const AsyncPOSConfig = Loadable(() => import('./POSConfig'));
export const AsyncVerifoneDeviceSetup = Loadable(() => import('./VerifoneSetup'));
export const AsyncVenuePOSLanding = Loadable(() => import('./VenuePOSLanding'));
export const AsyncCloverSportAdd = Loadable(() => import('./cloverSport/Add'));
export const AsyncCloverSportEdit = Loadable(() => import('./cloverSport/Edit'));

export const AsyncCloverFlexAdd = Loadable(() => import('./cloverFlex/Add'));
export const AsyncCloverFlexEdit = Loadable(() => import('./cloverFlex/Edit'));
