import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { api } from '../../API';
import { PromoCodeForm } from './Form';

interface AddCodeRouterParams {
  userId?: string;
  venueId?: string;
}

export class AddCode extends React.Component<RouteComponentProps<AddCodeRouterParams>, any> {
  handleSubmit = async (code) => {
    await api.createPromoCode(code);
    this.props.history.push('/promo-codes/list');
  };

  render() {
    const code: any = {};

    if (this.props.match.params.userId) {
      code.userId = this.props.match.params.userId;
    }

    if (this.props.match.params.venueId) {
      code.venueId = this.props.match.params.venueId;
    }

    return <PromoCodeForm code={code} onSubmit={this.handleSubmit} loading={false} />;
  }
}
