import * as React from 'react';
import { Form } from '../../components';
import { api } from '../../API';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { DataTable } from '../../components/DataTable';
import { JSONSchema7 } from 'json-schema';

const schema: JSONSchema7 = {
  type: 'object',
  properties: {
    start: {
      type: 'string',
      format: 'date',
    },
    end: {
      type: 'string',
      format: 'date',
    },
  },
};

export const PreorderReminderEmailList: React.FC<{}> = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any>(null);

  const handleSubmit = React.useCallback(
    async ({ formData }) => {
      setLoading(true);
      const { start, end } = formData;
      const startDate = new Date(start);
      startDate.setHours(0, 0, 0, 0);

      const endDate = new Date(end);
      endDate.setHours(23, 59, 59, 999);

      const res = await api.fetchPreorderList({
        start: startDate,
        end: endDate,
      });

      setData(res);
      setLoading(false);
    },
    [setLoading, setData]
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!data) {
    return (
      <Card className='w-100'>
        <CardHeader>Preorders</CardHeader>
        <CardBody>
          <Form schema={schema} onSubmit={handleSubmit} />
        </CardBody>
      </Card>
    );
  }

  return (
    <div className='w-100'>
      <Card className='w-100'>
        <CardHeader>Preorders</CardHeader>
        <CardBody>
          <DataTable
            headers={['date', 'userEmail', 'userId', 'venueId', 'venueName']}
            rows={data.map((preorder) => [
              preorder.date,
              preorder.userEmail,
              preorder.userId,
              preorder.venueId,
              preorder.venueName,
            ])}
          />
          <div className='text-center'>
            <Button tag='a' href={`/reject-reasons/add`} color='success'>
              Add
            </Button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
