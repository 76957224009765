import * as React from 'react';
import { Form } from '../../components';
import { api } from '../../API';
import { cloverFlexConfigSchema } from '../pos/cloverFlex/Form';
import { IntegrationStack } from '@getnoble/pos-service-v2';

const schema: any = {
  properties: {
    name: {
      type: 'string',
      title: 'Name',
    },
    tenantId: {
      type: 'string',
      title: 'Tenant ID',
    },
    type: {
      type: 'string',
      title: 'Type',
      enum: ['Square', 'Nextgen', 'AuthorizeNet', 'VerifoneSCA', 'CloverSport', 'CloverFlex'],
      default: 'Square',
    },
  },
  required: ['name', 'tenantId', 'type'],
  type: 'object',
  dependencies: {
    type: {
      oneOf: [
        {
          properties: {
            type: {
              enum: ['Square'],
            },
            squareMerchantId: {
              type: ['string', 'null'],
              title: 'Square',
            },
          },
          required: ['squareMerchantId'],
        },
        {
          properties: {
            type: {
              enum: ['Nextgen'],
            },
            nextgenConnectorConfigId: {
              type: ['string', 'null'],
              title: 'Nextgen',
            },
          },
          required: ['nextgenConnectorConfigId'],
        },
        {
          properties: {
            type: {
              enum: ['AuthorizeNet'],
            },
            authorizeNetMerchantId: {
              type: ['string', 'null'],
              title: 'AuthorizeNet',
            },
          },
          required: ['authorizeNetMerchantId'],
        },
        {
          properties: {
            type: {
              enum: ['VerifoneSCA'],
            },
            verifoneSCAConfigId: {
              type: ['string', 'null'],
              title: 'VerifoneSCA',
            },
          },
          required: ['verifoneSCAConfigId'],
        },
        {
          properties: {
            type: {
              enum: ['CloverSport'],
            },
            cloverSportConfigId: {
              type: ['string', 'null'],
              title: 'CloverSport',
            },
          },
          required: ['cloverSportConfigId'],
        },
        {
          properties: {
            type: {
              enum: ['CloverFlex'],
            },
            cloverFlexConfig: cloverFlexConfigSchema,
          },
        },
      ],
    },
  },
};

const uiSchema = {
  tenantId: {
    'ui:widget': 'entity',
    'ui:options': {
      label: true,
      load: api.getTenants,
      process: (venues) =>
        venues.map((venue) => ({
          value: venue.id,
          name: venue.name,
        })),
    },
  },
  squareMerchantId: {
    'ui:widget': 'entity',
    'ui:options': {
      label: true,
      load: api.getSquareMerchants,
      process: (venues) =>
        venues.map((venue) => ({
          value: venue.id,
          name: venue.id,
        })),
    },
  },
  authorizeNetMerchantId: {
    'ui:widget': 'entity',
    'ui:options': {
      label: true,
      load: api.getNextgenConfigs,
      process: (venues) =>
        venues.map((venue) => ({
          value: venue.id,
          name: venue.id,
        })),
    },
  },
  verifoneSCAConfigId: {
    'ui:widget': 'entity',
    'ui:options': {
      label: true,
      load: api.getVerifoneSca,
      process: (venues) =>
        venues.map((venue) => ({
          value: venue.id,
          name: venue.id,
        })),
    },
  },
  cloverSportConfigId: {
    'ui:widget': 'entity',
    'ui:options': {
      label: true,
      load: api.getCloverSportConfigs,
      process: (venues) =>
        venues.map((venue) => ({
          value: venue.id,
          name: venue.id,
        })),
    },
  },
};

type IntegrationStackFormProps = {
  integrationStack?: IntegrationStack;
  onSubmit(config: IntegrationStack): void;
};

export class IntegrationStackForm extends React.Component<IntegrationStackFormProps, any> {
  public onSubmit = ({ formData }) => {
    let data: IntegrationStack = {
      name: formData.name,
      tenantId: formData.tenantId,
      squareMerchantId: formData.squareMerchantId ? formData.squareMerchantId : null,
      nextgenConnectorConfigId: formData.nextgenConnectorConfigId ? formData.nextgenConnectorConfigId : null,
      authorizeNetMerchantId: formData.authorizeNetMerchantId ? formData.authorizeNetMerchantId : null,
      verifoneSCAConfigId: formData.verifoneSCAConfigId ? formData.verifoneSCAConfigId : null,
      cloverFlexConfig: formData.cloverFlexConfig,
    };
    this.props.onSubmit(data);
  };

  render() {
    return (
      <div className='w-100'>
        <Form formData={this.props.integrationStack} uiSchema={uiSchema} schema={schema} onSubmit={this.onSubmit} />
      </div>
    );
  }
}
