import { RouteComponentProps } from 'react-router';
import { session as schema } from '@getnoble/noble-json-schemas';
import { Form } from '../../components';
import * as React from 'react';
import { Alert, Card, CardBody, CardHeader } from 'reactstrap';
import { api } from '../../API';

type Props = RouteComponentProps<{ venueId: string; fulfillmentCenterId }>;

export const EditSession: React.FC<Props> = (props) => {
  const [session, setSession] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(true);

  const onSubmit = React.useCallback(
    async ({ formData }) => {
      setLoading(true);
      const deviceId = formData.deviceId ? formData.deviceId : null;
      await api.updateSession(props.match.params.fulfillmentCenterId, formData.acceptingOrders, deviceId);
      props.history.push('/home');
    },
    [props.history, props.match]
  );

  React.useEffect(() => {
    const fetch = async () => {
      const res = await api.fetchActiveSession(props.match.params.venueId, props.match.params.fulfillmentCenterId);
      setSession(res);
      setLoading(false);
    };

    fetch();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {session.session === null && <Alert color='danger'>No active session</Alert>}
      <Card>
        <CardHeader>Edit Session</CardHeader>
        <CardBody>
          <Form formData={session.session} schema={schema} onSubmit={onSubmit} />
        </CardBody>
      </Card>
    </>
  );
};
