import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { api } from '../../API';
import { ProductUpdateForm } from './Form';

export class AddProductUpdate extends React.Component<RouteComponentProps, any> {
  handleSubmit = async (update) => {
    await api.createProductUpdate(update);
    this.props.history.push('/product-updates');
  };

  render() {
    const update: any = {};

    return <ProductUpdateForm update={update} onSubmit={this.handleSubmit} loading={false} />;
  }
}
