import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { api } from '../../API';
import { RejectReasonForm } from './Form';

interface EditRejectReasonRouteParams {
  id: string;
}

export class EditRejectReason extends React.Component<RouteComponentProps<EditRejectReasonRouteParams>, any> {
  constructor(props) {
    super(props);

    this.state = { loading: false, rejectReason: null };
  }

  async componentDidMount() {
    const { id } = this.props.match.params;
    const rejectReason = await api.getRejectReason(id);
    this.setState({
      loading: false,
      rejectReason,
    });
  }

  handleSubmit = async (data) => {
    const { id } = this.props.match.params;
    await api.updateRejectReason(id, data);
    this.props.history.push(`/reject-reasons/list`);
  };

  render() {
    if (this.state.loading || this.state.rejectReason === null) {
      return <div>Loading...</div>;
    }

    return <RejectReasonForm rejectReason={this.state.rejectReason} onSubmit={this.handleSubmit} />;
  }
}
