import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export class ConfirmModal extends React.Component<any, any> {
  render() {
    const renderButton = (btn, i) => (
      <Button key={i} color={btn.color} onClick={btn.onClick}>
        {btn.title}
      </Button>
    );
    return (
      <div>
        <Modal isOpen={this.props.open} toggle={this.props.toggle} className={this.props.className}>
          <ModalHeader toggle={this.props.toggle}>{this.props.title}</ModalHeader>
          <ModalBody>{this.props.children}</ModalBody>
          <ModalFooter>{this.props.buttons.map(renderButton)}</ModalFooter>
        </Modal>
      </div>
    );
  }
}
