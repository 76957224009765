import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { api } from '../../API';
import { RoleForm } from './Form';

interface EditRoleRouteParams {
  id: string;
}

export class EditRole extends React.Component<RouteComponentProps<EditRoleRouteParams>, any> {
  constructor(props) {
    super(props);

    this.state = { loading: false, role: null };
  }

  async componentDidMount() {
    const { id } = this.props.match.params;
    const role = await api.getRole(id);
    this.setState({
      loading: false,
      role,
    });
  }

  handleSubmit = async (data) => {
    const { id } = this.props.match.params;
    await api.updateRole(id, data);
    this.props.history.push(`/roles/list`);
  };

  render() {
    if (this.state.loading || this.state.role === null) {
      return <div>Loading...</div>;
    }

    return <RoleForm role={this.state.role} add={false} onSubmit={this.handleSubmit} />;
  }
}
