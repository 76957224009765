import * as React from 'react';

export const OrderInfoRow = ({ text, value }) => {
  return (
    <div>
      <dt className='col-sm-3'>{text}</dt>
      <dd className='col-sm-9'>{value}</dd>
    </div>
  );
};

export const OrderInfo = ({ order }) => {
  const fields = [
    {
      text: 'ID',
      value: order.orderId,
    },
    {
      text: 'User',
      value: <a href={`/users/edit/consumer/${order.user?.id}`}>{order.user?.name}</a>,
    },
    {
      text: 'Date',
      value: order.createdAt,
    },
    {
      text: 'Number',
      value: order.orderNumber,
    },
    {
      text: 'Scheduled Time',
      value: order.scheduledTime,
    },
    {
      text: 'Status',
      value: order.status,
    },
    {
      text: 'Total',
      value: order.total,
    },
  ];

  return (
    <dl className='row'>
      {fields.map(({ text, value }, index) => (
        <OrderInfoRow key={index} text={text} value={value} />
      ))}
    </dl>
  );
};
