import { Form } from '../../components';
import { RouteComponentProps } from 'react-router';
import { Card, CardBody, CardHeader } from 'reactstrap';
import * as React from 'react';
import { api } from '../../API';
import { JSONSchema7 } from 'json-schema';

const schema: JSONSchema7 = {
  type: 'object',
  properties: {
    scheduledTime: {
      type: 'string',
    },
    // status: {
    //   type: 'integer',
    // },
    disbursementId: {
      type: ['string', 'null'],
    },
  },
};

interface UpdateOrderRouterParams {
  orderId: string;
}

type UpdateOrderProps = RouteComponentProps<UpdateOrderRouterParams>;

export const UpdateOrder: React.FC<UpdateOrderProps> = ({ match }) => {
  const { orderId } = match.params;
  const [loading, setLoading] = React.useState<boolean>(false);
  const [updated, setUpdated] = React.useState<boolean>(false);

  const onSubmit = React.useCallback(
    async ({ formData }) => {
      setLoading(true);
      await api.updateOrderInternally(orderId, formData);
      setUpdated(true);
      setLoading(false);
    },
    [setLoading, setUpdated, orderId]
  );

  return (
    <Card>
      <CardHeader>Update Order</CardHeader>
      <CardBody>
        {loading && <div className='text-warning'>Loading...</div>}
        {updated && <div className='text-danger'>Saved</div>}
        <Form schema={schema} onSubmit={onSubmit} />
      </CardBody>
    </Card>
  );
};
