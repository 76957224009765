import * as React from 'react';
import { Button, Card, CardBody, CardHeader, Form, FormGroup, Input, Label } from 'reactstrap';
import { toISOString } from '../../../utils';

const formatDate = (dateString) => {
  const [year, month, day] = dateString.split('-');
  return toISOString(new Date(year, month - 1, day, 0, 0, 0, 0));
};

export class OrderFilter extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      venueId: -1,
      orderId: '',
      orderNumber: '',
      start: '',
      end: '',
    };

    this.onChangeVenue = this.onChangeVenue.bind(this);
    this.onChangeDateStart = this.onChangeDateStart.bind(this);
    this.onChangeDateEnd = this.onChangeDateEnd.bind(this);
    this.onChangeOrderId = this.onChangeOrderId.bind(this);
    this.onChangeOrderNunber = this.onChangeOrderNunber.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChangeVenue(e) {
    const venueId = e.target.value;
    this.setState({ venueId });
  }

  onChangeDateStart(e) {
    const start = e.target.value;
    this.setState({ start });
  }

  onChangeDateEnd(e) {
    const end = e.target.value;
    this.setState({ end });
  }

  onChangeOrderId(e) {
    const orderId = e.target.value;
    this.setState({ orderId });
  }

  onChangeOrderNunber(e) {
    const orderNumber = e.target.value;
    this.setState({ orderNumber });
  }

  onSubmit(e) {
    e.preventDefault();

    const query: any = {};
    if (this.state.venueId > 0) {
      query.venueId = Number(this.state.venueId);
    }

    if (this.state.orderId) {
      query.id = Number(this.state.orderId);
    }

    if (this.state.orderNumber) {
      query.orderNumber = Number(this.state.orderNumber);
    }

    if (this.state.start && this.state.end) {
      query.start = formatDate(this.state.start);
      query.end = formatDate(this.state.end);
    }

    this.props.onChangeQuery(query);
  }

  render() {
    return (
      <Card className='mb-4'>
        <CardHeader>Filters</CardHeader>
        <CardBody>
          <Form inline={true} className='justify-content-between'>
            <FormGroup>
              <Label for='venue'>Venue</Label>
              <Input type='select' name='select' onChange={this.onChangeVenue} value={this.state.venueId}>
                <option value={-1}>All</option>
                {this.props.venues.map((venue) => (
                  <option key={venue.id} value={venue.id}>
                    {venue.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for='dateRange'>Date Range</Label>
              <Input type='date' name='date' id='start' value={this.state.start} onChange={this.onChangeDateStart} />
              <Input type='date' name='date' id='end' value={this.state.end} onChange={this.onChangeDateEnd} />
            </FormGroup>
            <FormGroup>
              <Label for='dateRange'>Order ID</Label>
              <Input type='text' name='email' id='orderId' value={this.state.orderId} onChange={this.onChangeOrderId} />
            </FormGroup>
            <FormGroup>
              <Label for='dateRange'>Order Number</Label>
              <Input
                type='text'
                name='email'
                id='orderNunber'
                value={this.state.orderNumber}
                onChange={this.onChangeOrderNunber}
              />
            </FormGroup>
            <Button type='submit' onClick={this.onSubmit}>
              Search
            </Button>
          </Form>
        </CardBody>
      </Card>
    );
  }
}
