import { Form } from '../../components';
import * as React from 'react';
import { Alert } from 'reactstrap';
import { api } from '../../API';
import { JSONSchema7 } from 'json-schema';

const schema: JSONSchema7 = {
  type: 'object',
  properties: {
    start: {
      type: 'string',
      format: 'date',
    },
    end: {
      type: 'string',
      format: 'date',
    },
  },
};

export const SendPreorderReminder: React.FC<{}> = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [saved, setSaved] = React.useState<boolean>(false);

  const handleSubmit = React.useCallback(
    async ({ formData }) => {
      setLoading(true);
      const { start, end } = formData;
      const startDate = new Date(start);
      startDate.setHours(0, 0, 0, 0);

      const endDate = new Date(end);
      endDate.setHours(23, 59, 59, 999);

      await api.sendPreorderReminderEmail({
        start: startDate,
        end: endDate,
      });

      setLoading(false);
      setSaved(true);
    },
    [setLoading, setSaved]
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  const savedAlert = saved && <Alert color='primary'>Sent</Alert>;

  return (
    <div className='w-100'>
      {savedAlert}
      <Form schema={schema} onSubmit={handleSubmit} />
    </div>
  );
};
