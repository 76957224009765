/* tslint:disable:max-classes-per-file */
import * as React from 'react';
import auth0 from 'auth0-js';
import Cookies from 'universal-cookie';
import { CookieNames } from './constants';
const jwtDecode = require('jwt-decode');

export class MFAAuthError extends Error {
  public mfaToken: string;
  public oobCode: string;
  public isMFAError = true;

  constructor(mfaToken: string, oobCode: string) {
    super('MFA Required');
    this.mfaToken = mfaToken;
    this.oobCode = oobCode;
  }
}

export class AuthService {
  public cookies;
  public auth0: any;
  public clientId: string;

  constructor(cookies?) {
    this.cookies = new Cookies(cookies);

    this.clientId = 'RN5zpiShUxeiQ3NzKvptczHKLmSQCeSN';

    this.auth0 = new auth0.WebAuth({
      clientID: this.clientId,
      domain: 'getnoble.auth0.com',
      responseType: 'token id_token',
      redirectUri: 'http://localhost:3000/login',
    });
  }

  login(username, password) {
    return new Promise((resolve, reject) => {
      this.auth0.client.login(
        {
          connection: 'Username-Password-Authentication',
          username,
          password,
          scope: 'openid profile',
          realm: 'Username-Password-Authentication',
          audience: 'backend-api',
        },
        (err, authResult) => {
          if (err) {
            if (err.code === 'mfa_required') {
              const mfaToken = err.original.response.body.mfa_token;
              this.sendChallenge(mfaToken)
                .then((json) => {
                  const error = new MFAAuthError(mfaToken, json.oob_code);
                  reject(error);
                })
                .catch(reject);
              return;
            }

            return reject(err);
          }

          const { accessToken, idToken, expiresIn } = authResult;
          this.setToken({ accessToken, idToken, expiresIn });
          resolve({ accessToken, idToken });
        }
      );
    });
  }

  async sendChallenge(mfaToken: string) {
    const response = await fetch('https://getnoble.auth0.com/mfa/challenge', {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        client_id: this.clientId,
        challenge_type: 'otp oob',
        mfa_token: mfaToken,
      }),
    });

    return response.json();
  }

  public async loginWithMFACode(mfaToken: string, code: string, oobCode: string) {
    const response = await fetch('https://getnoble.auth0.com/oauth/token', {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        client_id: this.clientId,
        mfa_token: mfaToken,
        oob_code: oobCode,
        binding_code: code,
        grant_type: 'http://auth0.com/oauth/grant-type/mfa-oob',
      }),
    });

    const json = await response.json();
    if (!response.ok) {
      throw new Error(JSON.stringify(json, null, 2));
    }

    const accessToken = json.access_token;
    const idToken = json.id_token;

    this.setToken({ accessToken, idToken, expiresIn: json.expires_in });
    return { accessToken, idToken };
  }

  setToken({ accessToken, idToken, expiresIn }: { accessToken: string; idToken: string; expiresIn: number }) {
    const options = {
      path: '/',
      domain: process.env.NODE_ENV === 'development' ? undefined : '.getnoble.co',
      expires: new Date(Date.now() + expiresIn * 1000),
    };

    this.cookies.set(CookieNames.accessToken, accessToken, options);
    this.cookies.set(CookieNames.idToken, idToken, options);
  }

  getAccessToken = () => {
    return this.cookies.get(CookieNames.accessToken);
  };

  getAccessTokenInfo() {
    const accessToken = this.cookies.get(CookieNames.accessToken);

    try {
      const payload = jwtDecode(accessToken);
      return payload;
    } catch {
      return null;
    }
  }

  get isLoggedIn() {
    const payload = this.getAccessTokenInfo();
    console.log(payload);
    return payload ? Date.now() < payload.exp * 1000 : false;
  }

  get isAdmin() {
    const payload = this.getAccessTokenInfo();
    return payload?.permissions?.includes('super-admin') && payload['http://noble/accountType'] === 'internal';
  }

  logout() {
    this.cookies.removeItem(CookieNames.accessToken);
    this.cookies.removeItem(CookieNames.idToken);
  }
}

export const Auth = new AuthService();

export const AuthContext = React.createContext<AuthService | null>(null);
