import { Auth } from '../../AuthService';
import { RouteComponentProps } from 'react-router';
import { Form, FormGroup, Button, Label } from 'reactstrap';
import { EnvironmentHeader } from '../../components/EnvironmentHeader';
import './Login.scss';
import * as React from 'react';

interface LoginState {
  loading: boolean;
  error: Error | null;
  email: string;
  password: string;
}

class LoginContainer extends React.PureComponent<RouteComponentProps, LoginState> {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: null,
      email: '',
      password: '',
    };
  }

  componentDidMount() {
    if (Auth.isAdmin) {
      this.props.history.push('/home');
    }
  }

  handleUsernameChange = (e) => {
    this.setState({
      email: e.target.value,
    });
  };

  handlePasswordChange = (e) => {
    this.setState({
      password: e.target.value,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({ loading: true });

    const { email, password } = this.state;

    try {
      await Auth.login(email, password);
      this.props.history.push('/home');
    } catch (error) {
      if (error.isMFAError) {
        this.props.history.push('/mfa', {
          mfaToken: error.mfaToken,
          oobCode: error.oobCode,
        });
      } else {
        this.setState({ loading: false, error });
      }
    }
  };

  render() {
    return (
      <div className='login-container'>
        <EnvironmentHeader />
        <div className='login-box'>
          <Form onSubmit={this.handleSubmit} method='post'>
            <p className='text-center'>{this.state.error && this.state.error.message}</p>
            <FormGroup>
              <Label for='username'>Email</Label>
              <input
                name='username'
                id='username'
                onChange={this.handleUsernameChange}
                className='login-input form-control'
                value={this.state.email}
              />
            </FormGroup>
            <FormGroup>
              <Label for='password'>Password</Label>
              <input
                type='password'
                name='password'
                id='password'
                onChange={this.handlePasswordChange}
                className='login-input form-control'
                value={this.state.password}
              />
            </FormGroup>
            <div className='text-center'>
              <Button color='primary' type='submit' disabled={this.state.loading} className='btn--rounded pl-4 pr-4'>
                Sign In
              </Button>
            </div>
          </Form>
        </div>
        <div className='login-version'>{process.env.RAZZLE_NOBLE_COMMIT_HASH}</div>
      </div>
    );
  }
}

export const LoginRoutes = {
  Login: LoginContainer,
};
