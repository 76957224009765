import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { api } from '../../API';
import { PromoCodeForm } from './Form';

interface EditCodeRouterParams {
  id: string;
}

export class EditCode extends React.Component<RouteComponentProps<EditCodeRouterParams>, any> {
  constructor(props) {
    super(props);

    this.state = { loading: true, code: null };
  }

  async componentDidMount() {
    const id = this.props.match.params.id;
    const code = await api.getPromoCode(id);
    this.setState({
      loading: false,
      code: {
        ...code,
        paymentMethodId: code.paymentMethod ? code.paymentMethod.id : null,
      },
    });
  }

  handleSubmit = async (code) => {
    const id = this.props.match.params.id;
    await api.updatePromoCode(id, code);
    this.props.history.push('/promo-codes/list');
  };

  render() {
    return <PromoCodeForm loading={this.state.loading} code={this.state.code} onSubmit={this.handleSubmit} />;
  }
}
