import * as React from 'react';
import { Form } from '../../components';
import { JSONSchema7 } from 'json-schema';

const schema: JSONSchema7 = {
  properties: {
    reason: {
      type: 'string',
    },
    blocksUser: {
      type: 'boolean',
      title: 'Block User?',
    },
  },
  required: ['reason', 'blocksUser'],
  type: 'object',
};

const uiSchema = {
  blocksUser: {
    'ui:widget': 'radio',
  },
};

export class RejectReasonForm extends React.Component<any, any> {
  public onSubmit = ({ formData }) => {
    this.props.onSubmit(formData);
  };

  render() {
    return (
      <div className='w-100'>
        <Form formData={this.props.rejectReason} uiSchema={uiSchema} schema={schema} onSubmit={this.onSubmit} />
      </div>
    );
  }
}
