import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { api } from '../../API';
import { IntegrationStackForm } from './Form';
import { IntegrationStackViewModel } from '@getnoble/pos-service-v2';

interface EditIntegrationStackRouteParams {
  id: string;
}

export class EditIntegrationStack extends React.Component<RouteComponentProps<EditIntegrationStackRouteParams>, any> {
  constructor(props) {
    super(props);

    this.state = { loading: false, integrationStack: null };
  }

  async componentDidMount() {
    const { id } = this.props.match.params;
    const integrationStack: IntegrationStackViewModel & { type?: string } = await api.getIntegrationStack(id);
    if (integrationStack.squareMerchant?.id) {
      integrationStack.type = 'Square';
    } else if (integrationStack.nextgenConnectorConfig?.id) {
      integrationStack.type = 'Nextgen';
    } else if (integrationStack.authorizeNetMerchant?.id) {
      integrationStack.type = 'AuthorizeNet';
    }
    //  else if (integrationStack.verifoneSCAConfig?.id) {
    //   integrationStack.type = 'VerifoneSCA';
    // }
    this.setState({
      loading: false,
      integrationStack,
    });
  }

  handleSubmit = async (data) => {
    const { id } = this.props.match.params;
    await api.updateIntegrationStack(id, data);
    this.props.history.push(`/integration-stack/list`);
  };

  render() {
    if (this.state.loading || this.state.integrationStack === null) {
      return <div>Loading...</div>;
    }

    return <IntegrationStackForm integrationStack={this.state.integrationStack} onSubmit={this.handleSubmit} />;
  }
}
