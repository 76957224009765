import * as React from 'react';
import { format } from 'date-fns';
import { RouteComponentProps } from 'react-router';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { api } from '../../API';
import { OrdersTable } from '../../components/OrdersTable';

interface OrdersRouterParams {
  venueId: string;
  fulfillmentCenterId: string;
}

const OrdersCard = (props) => {
  return (
    <Card className='mb-4'>
      <CardHeader>{props.title}</CardHeader>
      <CardBody>
        <OrdersTable sendReminder={props.sendReminder} showActions={true} {...props} />
      </CardBody>
    </Card>
  );
};

const RejectModalBody = ({
  order,
  rejectReasons,
  selectedReason,
  onChangeReason,
  onChangeSendNotification,
  sendNotification,
}) => {
  return (
    <ModalBody>
      <ul>
        <li>Order ID: {order.orderId}</li>
        <li>Created: {format(new Date(order.createdAt), 'MMMM d, yyyy h:mm:ss a')}</li>
        <li>Number: {order.orderNumber}</li>
        <li>Time Slot: {order.timeSlot ? order.timeSlot : <i>None</i>}</li>
        <li>Subtotal: ${order.cartTotal}</li>
        <li>Tip: ${order.tip.amount ?? 0}</li>
        <li>Total: ${order.total}</li>
        <li>
          <a href={`/users/edit/consumer/${order.user.id}`}>{order.user.name}</a>
        </li>
      </ul>
      <FormGroup>
        <Label>Reason</Label>
        <Input type='select' value={selectedReason} onChange={onChangeReason}>
          {rejectReasons.map((rejectReason) => (
            <option key={rejectReason.id} value={rejectReason.id}>
              {rejectReason.reason}
            </option>
          ))}
        </Input>
      </FormGroup>
      <FormGroup check={true}>
        <Label check={true}>
          <Input type='checkbox' value={sendNotification} onChange={onChangeSendNotification} />
          Send Notification
        </Label>
      </FormGroup>
    </ModalBody>
  );
};

export class OrdersList extends React.Component<RouteComponentProps<OrdersRouterParams>, any> {
  constructor(props) {
    super(props);

    this.state = {
      orders: null,
      rejectReasons: null,
      sendNotification: false,
      rejectOrder: null,
      loading: true,
      page: 1,
    };
  }

  componentDidMount() {
    this.fetch();
  }

  fetch = async () => {
    const { page } = this.state;
    const { venueId, fulfillmentCenterId } = this.props.match.params;
    const preOrders = await api.getOrders(venueId, fulfillmentCenterId, -2, page, 'scheduled_time');
    const openOrders = await api.getOrders(venueId, fulfillmentCenterId, 0, page, 'scheduled_time');
    const awaitingOrders = await api.getOrders(venueId, fulfillmentCenterId, 1, page, 'scheduled_time');
    const rejectReasons = await api.getRejectReasons();

    this.setState({
      loading: false,
      preOrders,
      openOrders,
      awaitingOrders,
      rejectReasons,
      selectedReason: rejectReasons[0].id,
      loadingDifferentPage: false,
    });
  };

  openScheduleOrder = async (orderId) => {
    this.setState({ loading: true });

    const { venueId, fulfillmentCenterId } = this.props.match.params;
    await api.openScheduleOrder(venueId, fulfillmentCenterId, [orderId]);
    await this.fetch();
  };

  updateOrder = async (orderId, status) => {
    this.setState({ loading: true });

    const { venueId, fulfillmentCenterId } = this.props.match.params;
    await api.updateOrder(venueId, fulfillmentCenterId, [orderId], status);
    await this.fetch();
  };

  completeLast10 = async () => {
    const awaitingOrders = this.state.awaitingOrders;
    const last10 = awaitingOrders
      // .slice(-10)
      .map((o) => o.orderId);

    this.setState({ loading: true });
    const { venueId, fulfillmentCenterId } = this.props.match.params;
    await api.updateOrder(venueId, fulfillmentCenterId, last10, 2);
    await this.fetch();
  };

  readyLast10 = async () => {
    const openOrders = this.state.openOrders;
    const last10 = openOrders.slice(-10).map((o) => o.orderId);

    this.setState({ loading: true });
    const { venueId, fulfillmentCenterId } = this.props.match.params;
    await api.updateOrder(venueId, fulfillmentCenterId, last10, 1);
    await this.fetch();
  };

  openLast10 = async () => {
    const preOrders = this.state.preOrders;
    const last10 = preOrders.slice(-10).map((o) => o.orderId);

    this.setState({ loading: true });
    const { venueId, fulfillmentCenterId } = this.props.match.params;
    await api.openScheduleOrder(venueId, fulfillmentCenterId, last10);
    await this.fetch();
  };

  updateOpenOrder = (orderId) => {
    this.updateOrder(orderId, 1);
  };

  updateAwaitingOrder = (orderId) => {
    this.updateOrder(orderId, 2);
  };

  onClickSendReminder = async (orderId) => {
    this.setState({ loading: true });
    await api.sendReminderPush(orderId);
    this.setState({ loading: false });
  };

  startRejectOrder = (rejectOrder) => {
    this.setState({
      rejectOrder,
    });
  };

  rejectOrder = async () => {
    const { selectedReason } = this.state;
    const orderId = this.state.rejectOrder.orderId;
    this.setState({ loading: true, rejectOrder: null });
    await api.rejectOrder(orderId, Number(selectedReason), this.state.sendNotification);
    await this.fetch();
    this.setState({ loading: false });
  };

  toggle = () => {
    this.setState({
      rejectOrder: null,
    });
  };

  onChangeReason = (e) => {
    const { value: selectedReason } = e.target;
    this.setState({ selectedReason });
  };

  onChangeSendNotification = () => {
    this.setState({ sendNotification: !this.state.sendNotification });
  };

  onClickPrevious = () => {
    if (this.state.page === 1) return;

    this.setState({
      page: this.state.page - 1,
    });

    this.fetch();
  };

  onClickNext = () => {
    this.setState({
      page: this.state.page + 1,
    });

    this.fetch();
  };

  render() {
    if (this.state.loading) {
      return <div>Loading...</div>;
    }

    return (
      <div className='w-100'>
        <div className='mt-3 mb-3'>
          Page {this.state.page} -<Button onClick={this.onClickPrevious}>Prev Page</Button>
          <Button onClick={this.onClickNext}>Next Page</Button>
        </div>
        <div className='mt-4 mb-4'>
          <Button color='primary' onClick={this.openLast10}>
            Open 10 Preorders
          </Button>
        </div>
        <OrdersCard
          orders={this.state.preOrders}
          updateOrder={this.openScheduleOrder}
          rejectOrder={this.startRejectOrder}
          title='Pre Orders'
        />
        <div className='mt-4 mb-4'>
          <Button color='danger' onClick={this.readyLast10}>
            Ready Last 10
          </Button>
        </div>
        <OrdersCard
          orders={this.state.openOrders}
          updateOrder={this.updateOpenOrder}
          rejectOrder={this.startRejectOrder}
          title='Open Orders'
        />
        <div className='mt-4 mb-4'>
          <Button color='primary' onClick={this.completeLast10}>
            Accept Last 10 Orders
          </Button>
        </div>
        <OrdersCard
          sendReminder={true}
          onClickSendReminder={this.onClickSendReminder}
          orders={this.state.awaitingOrders}
          updateOrder={this.updateAwaitingOrder}
          rejectOrder={this.startRejectOrder}
          title='Awaiting Pickup'
        />
        <Modal isOpen={!!this.state.rejectOrder} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Reject Order</ModalHeader>
          {this.state.rejectOrder && (
            <RejectModalBody
              order={this.state.rejectOrder}
              rejectReasons={this.state.rejectReasons}
              selectedReason={this.state.selectedReason}
              onChangeReason={this.onChangeReason}
              onChangeSendNotification={this.onChangeSendNotification}
              sendNotification={this.state.sendNotification}
            />
          )}
          <ModalFooter>
            <Button onClick={this.rejectOrder} color='danger'>
              Reject
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
