import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { api } from '../../API';
import { ProductUpdateForm } from './Form';

interface EditProductUpdateRouterParams {
  id: string;
}

export class EditProductUpdate extends React.Component<RouteComponentProps<EditProductUpdateRouterParams>, any> {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    const update = await api.getProductUpdate(this.props.match.params.id);
    this.setState({ update, loading: false });
  }

  handleSubmit = async (update) => {
    await api.updateProductUpdate(this.props.match.params.id, update);
    this.props.history.push('/product-updates');
  };

  render() {
    return <ProductUpdateForm update={this.state.update} onSubmit={this.handleSubmit} loading={this.state.loading} />;
  }
}
