import * as React from 'react';
import { Button, Card, CardBody, CardHeader, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Form } from '../../../components';
import { OrderFilter } from './Filter';
import { OrdersTable } from './Table';
import { OrderInfo } from './OrderInfo';
import { ViewOrderModal } from './ViewOrderModal';
import { JSONSchema7 } from 'json-schema';

const refundSchema: JSONSchema7 = {
  type: 'object',
  properties: {
    amount: {
      title: 'Amount (leave blank if you want to refund the whole order)',
      type: 'number',
    },
  },
};

export const Search = (props) => {
  const {
    onClickRefund,
    confirmRefund,
    rejectRefund,
    refundOrder,
    orders,
    loading,
    venues,
    onChangeQuery,
    onClickPrevious,
    onClickNext,
    onClickEdit,
    onClickViewOrder,
    clearDetailOrder,
    detailOrder,
  } = props;

  let modalContent;
  if (refundOrder) {
    modalContent = (
      <div>
        <p>Are you sure you would like to issue a refund for this order?</p>
        <OrderInfo order={refundOrder} />
        <Form schema={refundSchema} onSubmit={confirmRefund} />
      </div>
    );
  }

  return (
    <div className='w-100'>
      <OrderFilter venues={venues} onChangeQuery={onChangeQuery} />
      <Card className='mb-4'>
        <CardHeader>Results</CardHeader>
        <CardBody>
          <div className='mt-3 mb-3'>
            {onClickPrevious && <Button onClick={onClickPrevious}>Prev Page</Button>}
            {onClickNext && <Button onClick={onClickNext}>Next Page</Button>}
          </div>
          <OrdersTable
            onClickViewOrder={onClickViewOrder}
            onClickEdit={onClickEdit}
            onClickRefund={onClickRefund}
            orders={orders}
            loading={loading}
          />
        </CardBody>
      </Card>
      <Modal isOpen={!!modalContent} toggle={rejectRefund}>
        <ModalHeader toggle={rejectRefund}>Confirm Refund</ModalHeader>
        <ModalBody>{modalContent}</ModalBody>
      </Modal>
      <ViewOrderModal order={detailOrder} onToggle={clearDetailOrder} />
    </div>
  );
};
