import React from 'react';
import { Button, Input } from 'reactstrap';
import Dropzone from 'react-dropzone';

interface ImageUploadState {
  loading: boolean;
}

export class ImageUpload extends React.Component<any, ImageUploadState> {
  constructor(props) {
    super(props);

    this.state = { loading: false };
  }

  onChange = async (files) => {
    const { options } = this.props;

    this.setState({ loading: true });
    const resp = await options.upload(files);
    const { urls } = resp;

    this.setState({ loading: false });
    this.props.onChange(urls[0]);
  };

  render() {
    if (this.state.loading) {
      return <div>Loading...</div>;
    }

    const { options } = this.props;

    let previewImage;
    if (options.showImage) {
      previewImage = (
        <img className='float-right' style={{ maxHeight: 200 + 'px', maxWidth: 200 + 'px' }} src={this.props.value} />
      );
    }

    let label;
    if (this.props.value) {
      const parser = document.createElement('a');
      parser.href = this.props.value;
      label = <small className='me-2'>{parser.pathname.substr(1)}</small>;
    }

    return (
      <div className='clearfix'>
        {previewImage}
        <div>{label}</div>
        <Dropzone onDrop={this.onChange}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Button type='button' color='outline-primary' className='btn--rounded-padded'>
                Upload
              </Button>
            </div>
          )}
        </Dropzone>
        <Input className='mt-3' value={this.props.value} onChange={(e) => this.props.onChange(e.currentTarget.value)} />
      </div>
    );
  }
}
