import { Form } from '../../components';
import { RouteComponentProps } from 'react-router';
import { Card, CardBody, CardHeader } from 'reactstrap';
import * as React from 'react';
import { api } from '../../API';
import { JSONSchema7 } from 'json-schema';

const schema: JSONSchema7 = {
  type: 'object',
  properties: {
    orderId: {
      title: 'Order ID',
      type: 'number',
    },
  },
};

export const ConfirmOrder: React.FC<RouteComponentProps<{}>> = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [updated, setUpdated] = React.useState<boolean>(false);

  const onSubmit = React.useCallback(
    async ({ formData }) => {
      setLoading(true);
      await api.confirmOrder(formData.orderId);
      setUpdated(true);
      setLoading(false);
    },
    [setLoading, setUpdated]
  );

  return (
    <Card>
      <CardHeader>Confirm Order</CardHeader>
      <CardBody>
        {loading && <div className='text-warning'>Loading...</div>}
        {updated && <div className='text-danger'>Confirmed</div>}
        <Form schema={schema} onSubmit={onSubmit} />
      </CardBody>
    </Card>
  );
};
