import { path } from 'static-path';

export const paths = {
  deliveryConfigurations: {
    list: path('/venues/:venueId/delivery-configurations'),
    add: path('/venues/:venueId/delivery-configurations/add'),
    edit: path('/venues/:venueId/delivery-configurations/edit/:id'),
  },

  events: path('/venues/:venueId/gem'),

  fulfillmentCenters: {
    markAsSoldOut: path('/venues/:venueId/fulfillment-centers/:fulfillmentCenterId/mark-sold-out'),
    edit: path('/venues/:venueId/fulfillment-centers/edit/:id'),
  },

  venues: {
    edit: path('/venues/edit/:id'),
    add: path('/venues/add'),
    manageOrder: path('/venues/:venueId/fulfillment-centers/:fulfillmentCenterId/order'),
  },

  partners: {
    list: path('/partners'),
    add: path('/partners/add'),
    edit: path('/partners/edit/:id'),
  },

  partnerLocationGroup: {
    list: path('/partner-location-group'),
    add: path('/partner-location-group/add'),
    edit: path('/partner-location-group/edit/:id'),
  },

  partnerLocation: {
    list: path('/partner-location-group/:groupId/locations'),
    add: path('/partner-location-group/:groupId/locations/add'),
    edit: path('/partner-location-group/:groupId/locations/edit/:id'),
    viewMenu: path('/partner-location-group/:groupId/locations/viewMenu/:id'),
  },

  orders: {
    resendReceipt: path('/orders/resend-receipt'),
  },

  timeSlots: {
    listForFulfillmentCenter: path('/fulfillment-centers/:fulfillmentCenterId/time-slots'),
    listForVenue: path('/venues/:venueId/time-slots'),
    create: path('/time-slots/create'),
    update: path('/time-slots/update/:id'),
  },

  posOrders: path('/venues/:venueId/pos-orders/:sessionId'),
  posConfig: path('/venues/:venueId/pos-config'),
  setupVerifoneDevice: path('/verifone/setup-device'),

  venuePosLanding: path('/venues/:venueId/pos-setup'),

  posv2: {
    integrations: {
      list: path('/integration-stack/list'),
      syncingConfigs: path('/sync-config/list/:integrationStackId'),
    },
    cloverSport: {
      add: path('/venues/:venueId/integration-stack/:integrationStackId/clover-sport'),
      editForVenue: path('/venues/:venueId/integration-stack/:integrationStackId/clover-sport/:cloverSportConfigId'),
      edit: path('/integration-stack/:integrationStackId/clover-sport/:cloverSportConfigId'),
    },
    cloverFlex: {
      add: path('/integration-stack/:integrationStackId/clover-flex'),
      edit: path('/integration-stack/:integrationStackId/clover-flex/:cloverFlexConfigId'),
    },
  },
};
