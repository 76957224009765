import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { AuthContext } from './AuthService';

export const PrivateRoute: React.FC<RouteProps> = (props) => {
  const auth = React.useContext(AuthContext);
  const { component: Component, ...rest } = props as any;

  const renderRoute = React.useCallback(
    (routeProps) => {
      if (auth!.isAdmin) {
        return <Component {...routeProps} />;
      } else {
        return <Redirect to='/' />;
      }
    },
    [Component, auth]
  );

  return <Route {...rest} render={renderRoute} />;
};
