import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { api } from '../../API';
import { UserForm } from './Form';

interface EditUserRouterParams {
  id: string;
}

export class EditUser extends React.Component<RouteComponentProps<EditUserRouterParams>, any> {
  constructor(props) {
    super(props);
    this.state = { loading: false, user: null };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    api.getUser(id).then((user) => {
      delete user.id;
      delete user.joined;
      if (user.bartender) {
        delete user.bartender.venue;
      }

      if (user.operatorManager) {
        delete user.operatorManager.venueOperator;
      }

      this.setState({
        loading: false,
        user,
      });
    });
  }

  async handleSubmit(data) {
    const id = this.props.match.params.id;
    await api.updateUser(id, data);
    this.props.history.push(`/users/list/all/internal`);
  }

  render() {
    if (this.state.loading || this.state.user === null) {
      return <div>Loading...</div>;
    }

    return <UserForm user={this.state.user} onSubmit={this.handleSubmit} />;
  }
}
