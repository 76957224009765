import * as React from 'react';
import { Table } from 'reactstrap';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';

interface RawDataTableProps {
  data: object[];
}

const Cell: React.FC<{ content: unknown }> = ({ content }) => {
  if (Array.isArray(content)) {
    return (
      <td>
        {content.map((el, i) => (
          <pre key={i}>{JSON.stringify(el, null, 2)}</pre>
        ))}
      </td>
    );
  } else {
    return (
      <td>
        <pre>{JSON.stringify(content, null, 2)}</pre>
      </td>
    );
  }
};

const TableRow = ({ row }) => {
  return (
    <tr>
      {Object.entries(row).map(([key, value]) => (
        <Cell key={key} content={value} />
      ))}
    </tr>
  );
};

const RawDataTableMain: React.FC<RawDataTableProps> = ({ data }) => {
  const [tab, setTab] = React.useState(0);

  return (
    <div className='tab-panel'>
      <Nav key='nav' tabs={true}>
        <NavItem>
          <NavLink className={classnames({ active: tab === 0 })} role='button' onClick={() => setTab(0)}>
            Table
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className={classnames({ active: tab === 1 })} role='button' onClick={() => setTab(1)}>
            Data
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent key='content' activeTab={tab}>
        <TabPane tabId={0}>
          <Table striped={true}>
            <thead>
              <tr>
                {Object.keys(data[0] ?? {}).map((rowKey, i) => (
                  <th key={i}>{rowKey}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, i) => (
                <TableRow key={i} row={row} />
              ))}
            </tbody>
          </Table>
        </TabPane>
        <TabPane tabId={1}>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </TabPane>
      </TabContent>
    </div>
  );
};

export const RawDataTable: React.FC<RawDataTableProps> = ({ data }) => {
  if (!data || !data.length) {
    return <div>No data</div>;
  }

  return <RawDataTableMain data={data} />;
};
