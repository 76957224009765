import * as React from 'react';
import { Table } from 'reactstrap';

interface DataTableProps {
  headers: any[];
  rows: any[][];
}

const TableRow = ({ row }) => {
  return (
    <tr>
      {row.map((column, i) => (
        <td key={i}>{column}</td>
      ))}
    </tr>
  );
};

export class DataTable extends React.Component<DataTableProps, {}> {
  render() {
    return (
      <Table striped={true}>
        <thead>
          <tr>
            {this.props.headers.map((header, i) => (
              <th key={i}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {this.props.rows.map((row, i) => (
            <TableRow key={i} row={row} />
          ))}
        </tbody>
      </Table>
    );
  }
}
