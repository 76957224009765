import * as React from 'react';
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from 'reactstrap';
import { paths } from '../../paths';
import { EnvironmentHeader } from '../EnvironmentHeader';
import './Layout.scss';
const Logo = require('./noble.jpg');

const MainNav: React.FC<{ isOpen: boolean }> = ({ isOpen }) => {
  // hack to get around server rendering issues w/bootstraps dropdowns
  const [showNav, setShowNav] = React.useState(false);
  React.useEffect(() => {
    setImmediate(() => {
      if (typeof window !== 'undefined') {
        setShowNav(true);
      }
    });
  }, []);

  return (
    <Collapse isOpen={isOpen} navbar={true}>
      {showNav && (
        <Nav className='ms-auto' navbar={true}>
          <NavItem key='/home'>
            <NavLink href='/home'>Venue Operators</NavLink>
          </NavItem>

          <UncontrolledDropdown nav={true} inNavbar={true}>
            <DropdownToggle nav={true} caret={true}>
              Orders
            </DropdownToggle>
            <DropdownMenu end={true}>
              <DropdownItem href='/orders/search'>Search Orders</DropdownItem>
              <DropdownItem href='/orders/change-user'>Change User</DropdownItem>
              <DropdownItem href='/orders/confirm'>Confirm Order</DropdownItem>
              <DropdownItem href={paths.orders.resendReceipt({})}>Resend Receipt</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>

          <UncontrolledDropdown nav={true} inNavbar={true}>
            <DropdownToggle nav={true} caret={true}>
              Users
            </DropdownToggle>
            <DropdownMenu end={true}>
              <DropdownItem href='/users/search'>Search</DropdownItem>
              <DropdownItem href='/roles/list'>Roles</DropdownItem>
              <DropdownItem href='/users/invite'>Invite User</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>

          <UncontrolledDropdown nav={true} inNavbar={true}>
            <DropdownToggle nav={true} caret={true}>
              Promo Codes
            </DropdownToggle>
            <DropdownMenu end={true}>
              <DropdownItem href='/promo-codes/list'>Promo Codes</DropdownItem>
              <DropdownItem href='/brand-split-payment-method/list'>Brand Split Payment Methods</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>

          <UncontrolledDropdown nav={true} inNavbar={true}>
            <DropdownToggle nav={true} caret={true}>
              Menu
            </DropdownToggle>
            <DropdownMenu end={true}>
              <DropdownItem href='/drinks'>Drinks</DropdownItem>
              <DropdownItem href='/drink-options'>Drink Options</DropdownItem>
              <DropdownItem href='/menu-templates/list'>Menu Templates</DropdownItem>
              <DropdownItem href='/menu-styles'>Menu Styles</DropdownItem>
              <DropdownItem href='/option-groups'>Option Groups</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>

          <NavItem>
            <NavLink href='/reject-reasons/list'>Reject Reasons</NavLink>
          </NavItem>

          <NavItem>
            <NavLink href='/features'>Features</NavLink>
          </NavItem>

          <UncontrolledDropdown nav={true} inNavbar={true}>
            <DropdownToggle nav={true} caret={true}>
              Integrations
            </DropdownToggle>
            <DropdownMenu end={true}>
              <DropdownItem href='/freedom-pay-configs/list'>Freedom Pay Credentials</DropdownItem>
              <DropdownItem href='#'>Authorize.net Credentials</DropdownItem>
              <DropdownItem href='/textmunication-configs/list'>Textmunication Credentials</DropdownItem>
              <DropdownItem href='/create-braintree-merchant'>Create Braintree Merchant</DropdownItem>
              <DropdownItem href='/braintree-status'>Check Braintree Merchant</DropdownItem>
              <DropdownItem href={paths.setupVerifoneDevice({})}>Setup Verifone Device</DropdownItem>
              <hr></hr>
              <DropdownItem href='/integration-stack/list'>Integration Stack List</DropdownItem>
              <DropdownItem href='/sync-config/list'>Sync Config List</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>

          <NavItem>
            <NavLink href='/product-updates'>Product Updates</NavLink>
          </NavItem>

          <UncontrolledDropdown nav={true} inNavbar={true}>
            <DropdownToggle nav={true} caret={true}>
              Preorders
            </DropdownToggle>
            <DropdownMenu end={true}>
              <NavItem>
                <NavLink href='/preorder-reminder'>Send Preorder Reminder</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href='/preorder-reminder-emails'>Send Preorder Reminder Emails</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href='/preorder-reminder-emails-response'>View Preorder Reminder Emails</NavLink>
              </NavItem>
            </DropdownMenu>
          </UncontrolledDropdown>

          <UncontrolledDropdown nav={true} inNavbar={true}>
            <DropdownToggle nav={true} caret={true}>
              API Partners
            </DropdownToggle>
            <DropdownMenu end={true}>
              <NavItem>
                <NavLink href={paths.partners.list({})}>API Partners</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href={paths.partnerLocationGroup.list({})}>Partner Location Groups</NavLink>
              </NavItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      )}
    </Collapse>
  );
};

export class Layout extends React.Component<React.PropsWithChildren<{}>, { isOpen: boolean }> {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    return (
      <div className='layout-container' suppressHydrationWarning={true}>
        <EnvironmentHeader />
        <div>
          <Navbar color='light' light={true} expand='md'>
            <NavbarBrand href='/home'>
              <img src={Logo} height='40px' />
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <MainNav isOpen={this.state.isOpen} />
          </Navbar>
        </div>
        <div className='container mt-5 mb-5'>{this.props.children}</div>
        <div className='layout-version'>{process.env.RAZZLE_NOBLE_COMMIT_HASH}</div>
      </div>
    );
  }
}
