import * as React from 'react';
import { format } from 'date-fns';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { api } from '../../API';
import { DataTable } from '../../components/DataTable';

export interface ProductUpdateListState {
  updates: any[];
  loading: boolean;
}

export class ProductUpdateList extends React.Component<{}, ProductUpdateListState> {
  constructor(props) {
    super(props);
    this.state = { updates: [], loading: true };
  }

  async componentDidMount() {
    const updates = await api.getProductUpdates();
    this.setState({
      loading: false,
      updates,
    });
  }

  render() {
    if (this.state.loading) {
      return <div>Loading...</div>;
    } else {
      return (
        <div className='w-100'>
          <Card>
            <CardHeader>Product Updates</CardHeader>
            <CardBody>
              <DataTable
                headers={['Notes', 'Date', 'Actions']}
                rows={this.state.updates.map((update) => [
                  <div key={`update-notes-${update.id}`} dangerouslySetInnerHTML={{ __html: update.releaseNotes }} />,
                  format(new Date(update.releaseDate), 'MMMM d, yyyy'),
                  <div key='actions'>
                    <Button tag='a' href={`/product-updates/edit/${update.id}`} color='success'>
                      Edit
                    </Button>
                  </div>,
                ])}
              />
            </CardBody>
          </Card>
          <div className='text-center mt-3'>
            <Button tag='a' href='/product-updates/add'>
              Add Update
            </Button>
          </div>
        </div>
      );
    }
  }
}
