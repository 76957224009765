import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { api } from '../../API';
import { SyncConfigForm } from './Form';

interface EditSyncConfigRouteParams {
  id: string;
}

export class EditSyncConfig extends React.Component<RouteComponentProps<EditSyncConfigRouteParams>, any> {
  constructor(props) {
    super(props);

    this.state = { loading: false, syncConfig: null };
  }

  async componentDidMount() {
    const { id } = this.props.match.params;
    const syncConfig = await api.getSyncConfig(id);
    if (syncConfig.menuSyncConfig) {
      syncConfig.menuSyncConfig = JSON.stringify(syncConfig.menuSyncConfig, null, 2);
    }

    this.setState({
      loading: false,
      syncConfig,
    });
  }

  handleSubmit = async (data) => {
    const { id } = this.props.match.params;
    await api.updateSyncConfig(id, data);
    this.props.history.push(`/sync-config/list`);
  };

  render() {
    if (this.state.loading || this.state.syncConfig === null) {
      return <div>Loading...</div>;
    }

    return <SyncConfigForm syncConfig={this.state.syncConfig} onSubmit={this.handleSubmit} />;
  }
}
