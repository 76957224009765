import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { api } from '../../API';
import { IntegrationStackForm } from './Form';

export class AddIntegrationStack extends React.Component<RouteComponentProps, any> {
  handleSubmit = async (data) => {
    await api.createIntegrationStack(data);
    this.props.history.push('/integration-stack/list');
  };

  render() {
    return <IntegrationStackForm onSubmit={this.handleSubmit} />;
  }
}
