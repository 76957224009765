import * as React from 'react';
import { Form } from '../../components';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { JSONSchema7 } from 'json-schema';

const addSchema: JSONSchema7 = {
  properties: {
    auth0Id: {
      type: 'string',
      title: 'Auth0 ID',
    },
    name: {
      type: 'string',
      title: 'Name',
    },
    internalOnly: {
      title: 'For Noble Employees only?',
      type: 'boolean',
    },
    allowOperatorAccess: {
      title: 'Allow access to all venues in group?',
      type: 'boolean',
    },
    permissions: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
  },
  required: ['name', 'internalOnly', 'allowOperatorAccess'],
  type: 'object',
};

const editSchema: JSONSchema7 = {
  properties: {
    name: {
      type: 'string',
      title: 'Name',
    },
    internalOnly: {
      title: 'For Noble Employees only?',
      type: 'boolean',
    },
    allowOperatorAccess: {
      title: 'Allow access to all venues in group?',
      type: 'boolean',
    },
    permissions: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
  },
  required: ['name', 'internalOnly', 'allowOperatorAccess'],
  type: 'object',
};

const uiSchema = {
  internalOnly: {
    'ui:widget': 'radio',
  },
  allowOperatorAccess: {
    'ui:widget': 'radio',
  },
};

export class RoleForm extends React.Component<any, any> {
  public onSubmit = ({ formData }) => {
    this.props.onSubmit(formData);
  };

  render() {
    return (
      <Card className='w-100'>
        <CardHeader>Roles</CardHeader>
        <CardBody>
          <Form
            formData={this.props.role}
            uiSchema={uiSchema}
            schema={this.props.add ? addSchema : editSchema}
            onSubmit={this.onSubmit}
          />
        </CardBody>
      </Card>
    );
  }
}
