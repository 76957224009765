import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { api } from '../../API';
import { RejectReasonForm } from './Form';

export class AddRejectReason extends React.Component<RouteComponentProps, any> {
  handleSubmit = async (data) => {
    await api.createRejectReason(data);
    this.props.history.push('/reject-reasons/list');
  };

  render() {
    return <RejectReasonForm onSubmit={this.handleSubmit} />;
  }
}
