import * as React from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';

interface TabPanelProps<T> {
  fillContainer?: boolean;
  activeTab: number;
  sections: T[];
  renderContent(section: T, index: number);
  toggle(index: number);
  renderTitle(section: T, index: number);
}

export class TabPanel<T> extends React.Component<TabPanelProps<T>> {
  static defaultProps = {
    fillContainer: true,
  };

  renderPane = (section, index) => {
    return (
      <TabPane key={index} tabId={index}>
        {this.props.renderContent(section, index)}
      </TabPane>
    );
  };

  renderNavItem = (section, index) => {
    const classes = classnames({ active: this.props.activeTab === index });
    const toggle = () => this.props.toggle(index);

    return (
      <NavItem key={index}>
        <NavLink role='button' className={classes} onClick={toggle}>
          {this.props.renderTitle(section, index)}
        </NavLink>
      </NavItem>
    );
  };

  render() {
    const { sections, activeTab, fillContainer } = this.props;

    return (
      <div className={classnames('tab-panel', fillContainer && 'tab-panel-full')}>
        <Nav key='nav' tabs={true}>
          {sections.map(this.renderNavItem)}
        </Nav>
        <TabContent key='content' activeTab={activeTab}>
          {sections.map(this.renderPane)}
        </TabContent>
      </div>
    );
  }
}
