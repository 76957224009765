import { Form } from '../../components';
import { venueInvite as schema } from '@getnoble/noble-json-schemas';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { api } from '../../API';
import { JSONSchema7 } from 'json-schema';

const schema: JSONSchema7 = {
  type: 'object',
  required: ['roleId', 'venueId', 'operatorId', 'firstName', 'lastName', 'email'],
  properties: {
    roleId: {
      title: 'Role',
      type: 'string',
    },
    firstName: {
      title: 'First Name',
      type: 'string',
    },
    lastName: {
      title: 'Last name',
      type: 'string',
    },
    email: {
      title: 'Email',
      type: 'string',
    },
    operatorId: {
      title: 'Operator',
      type: 'integer',
    },
    venueId: {
      title: 'Venue',
      type: 'integer',
    },
  },
};

const uiSchema = {
  operatorId: {
    'ui:widget': 'entity',
    'ui:options': {
      label: true,
      load: api.getVenueOperators,
      process: (operators) =>
        operators.map((operator) => ({
          value: operator.id,
          name: operator.name,
        })),
    },
  },
  venueId: {
    'ui:widget': 'entity',
    'ui:options': {
      label: true,
      load: api.getVenues,
      process: (venues) =>
        venues.map((venue) => ({
          value: venue.id,
          name: venue.name,
        })),
    },
  },
  roleId: {
    'ui:widget': 'entity',
    'ui:options': {
      label: true,
      load: api.getRoles,
      type: 'string',
      process: (roles) => roles.map((role) => ({ value: role.id, name: role.name })),
    },
  },
};

export class Invite extends React.Component<RouteComponentProps, any> {
  handleSubmit = async ({ formData }) => {
    await api.sendUserInvite(formData);
    this.props.history.push('/home');
  };

  render() {
    return <Form schema={schema} onSubmit={this.handleSubmit} uiSchema={uiSchema} />;
  }
}
