import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { App } from './App';
import { AuthContext, AuthService } from './AuthService';

hydrateRoot(
  document.getElementById('root'),
  <AuthContext.Provider value={new AuthService()}>
    <Router>
      <App />
    </Router>
  </AuthContext.Provider>
);

if ((module as any).hot) {
  (module as any).hot.accept();
}
