import * as React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { OrderInfo } from './OrderInfo';

type ViewOrderModalProps = {
  order: any | null;
  onToggle();
};

export const ViewOrderModal: React.FC<ViewOrderModalProps> = ({ order, onToggle }) => {
  let modalContent;
  if (order) {
    modalContent = (
      <div>
        <OrderInfo order={order} />
        <h2>Cart</h2>
        <ul>
          {order.cart.map((cartRow) => (
            <li key={cartRow.id}>
              {cartRow.item.name}
              <ul>
                {cartRow.options.map((o) => (
                  <li key={o.id}>{JSON.stringify(o, null, 2)}</li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return (
    <Modal isOpen={!!modalContent} toggle={onToggle}>
      <ModalHeader toggle={onToggle}>View Order: {order?.orderId}</ModalHeader>
      <ModalBody>{modalContent}</ModalBody>
    </Modal>
  );
};
