import * as React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { api } from '../../API';
import { Table } from 'reactstrap';
import { SyncRecord } from '@getnoble/pos-service-v2';
import { SyncRecordRow } from './SyncRecordRow';

export class SyncRecordsList extends React.Component<any, { loading: boolean; syncRecords: SyncRecord[] }> {
  constructor(props) {
    super(props);

    this.state = { syncRecords: [], loading: true };
  }

  fetch = async () => {
    const { id } = this.props.match.params;
    const syncRecords = await api.getSyncConfigRecords(id);
    this.setState({
      loading: false,
      syncRecords,
    });
  };

  componentDidMount() {
    this.fetch();
  }

  render() {
    if (this.state.loading) {
      return <div>Loading...</div>;
    } else {
      return (
        <Card className='w-100'>
          <CardHeader>Sync Records List</CardHeader>
          <CardBody>
            <Table striped={true}>
              <thead>
                <tr>
                  {['Start Time', 'End Time', 'Failure message', 'Status', ''].map((header, i) => (
                    <th key={i}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {this.state.syncRecords.map((record) => (
                  <SyncRecordRow key={record.id} syncRecord={record} />
                ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      );
    }
  }
}
