/* tslint:disable */
import * as React from 'react';
import fetch from 'isomorphic-fetch';
import Cookies from 'universal-cookie';
import config from './config';
import {
  Configuration,
  IntegrationStack,
  ManagementApi,
  ManagementV1CloverSportConfigsIdPutRequest,
  ManagementV1CloverSportConfigsPostRequest,
  ManagementV1CloverFlexConfigsPostRequest,
  ManagementV1CloverFlexConfigsIdPutRequest,
  NobleVenueSyncConfig,
  SyncConfigViewModel,
  Tenant,
} from '@getnoble/pos-service-v2';
import { byName } from './utils';
import { AuthContext } from './AuthService';

const API_URL = '/api';
const POS_API_URL = '/pos-api';

const formatError = (text: string) => {
  try {
    const json = JSON.parse(text);
    return JSON.stringify(json, null, 2);
  } catch {
    return text;
  }
};

async function processResponse(response) {
  if (response.status === 200) {
    return response.json();
  } else {
    try {
      const text = await response.text();
      if (typeof window !== 'undefined') {
        alert(formatError(text));
      }
      throw new Error(text);
    } catch (error) {
      throw error;
    }
  }
}

const createApi = (getToken: () => string) => {
  function authHeaders() {
    return {
      Authorization: `Bearer ${getToken()}`,
    };
  }

  type APIType = 'api' | 'pos' | 'internal';

  const authorizedRequest = async (api: APIType, endpoint, options?) => {
    let baseUrl: string;
    if (api === 'internal') {
      baseUrl = '/admin-api';
    } else if (typeof window !== 'undefined') {
      baseUrl = api === 'api' ? API_URL : POS_API_URL;
    } else {
      baseUrl = api === 'api' ? config.API_URL : config.POS_API_URL;
    }

    const headers = options && options.headers;
    const response = await fetch(baseUrl + endpoint, {
      ...options,
      headers: {
        ...authHeaders(),
        ...headers,
      },
    });

    return processResponse(response);
  };

  const posConfig = new Configuration({
    basePath: '/pos-v2-api',
  });

  const posApi = new ManagementApi(posConfig);

  const authorizedDelete = async (api: APIType, endpoint) => {
    return authorizedRequest(api, endpoint, {
      method: 'DELETE',
    });
  };

  const authorizedPost = async (api: APIType, endpoint, data = {}) => {
    return authorizedRequest(api, endpoint, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    });
  };

  const authorizedPut = async (api: APIType, endpoint, data) => {
    return authorizedRequest(api, endpoint, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(data),
    });
  };

  const authorizedUpload = async (endpoint, files) => {
    const form = new FormData();
    for (const key of Object.keys(files)) {
      const file = files[key];
      form.append(key, file, file.name);
    }

    const response = await fetch(API_URL + endpoint, {
      headers: {
        ...authHeaders(),
      },
      method: 'POST',
      body: form,
    });

    return processResponse(response);
  };

  const upload = (files) => {
    return authorizedUpload('/admin/v1/upload/public', files);
  };

  const privateUpload = (files) => {
    return authorizedUpload('/admin/v1/upload/private', files);
  };

  const sendNotification = (notification) =>
    authorizedPost('api', '/api/v1/management/send-notification', notification);

  /*
    Venues
  */

  const ADMIN_PREFIX = '/admin/v1';

  const getVenues = async (operatorId?) => {
    const qs = operatorId ? `?operatorId=${operatorId}` : '';
    const response = await authorizedRequest('api', `${ADMIN_PREFIX}/venues${qs}`);
    return response.sort(byName);
  };

  const getSyncDataType = async () => {
    let SyncDataType = [
      {
        value: 'Menu',
        name: 'Menu',
      },
      {
        value: 'DeliveryLocations',
        name: 'Delivery Locations',
      },
      {
        value: 'FulfillmentCenters',
        name: 'Fulfillment Centers',
      },
    ];
    return SyncDataType;
  };

  const getActiveVenues = async () => {
    const venues = await getVenues();
    return venues.filter((v) => !v.deleted && !v.hidden);
  };

  const getVenue = (venue_id) => authorizedRequest('api', `${ADMIN_PREFIX}/venues/${venue_id}`);
  const createVenue = (data) => authorizedPost('api', `${ADMIN_PREFIX}/venues`, data);
  const updateVenue = (venue_id, data) => authorizedPut('api', `${ADMIN_PREFIX}/venues/${venue_id}`, data);
  const deleteVenue = (venue_id) => authorizedDelete('api', `${ADMIN_PREFIX}/venues/${venue_id}`);

  const getBraintreeMerchantStatus = (merchantId) =>
    authorizedPost('api', `${ADMIN_PREFIX}/venues/braintree/lookup-all/${merchantId}`, {});
  const getAllBraintreeMerchantStatuses = () =>
    authorizedPost('api', `${ADMIN_PREFIX}/venues/braintree/lookup-all`, {});

  const updateSession = (centerId, acceptingOrders, deviceId?: string | null) =>
    authorizedPut('api', `${ADMIN_PREFIX}/fulfillment-centers/${centerId}/sessions`, {
      acceptingOrders,
      deviceId,
    });
  const fetchActiveSession = (venueId, fulfillmentCenterId) =>
    authorizedRequest('api', `${ADMIN_PREFIX}/venues/${venueId}/fulfillment-centers/${fulfillmentCenterId}/session`);

  const removeDeviceId = async (venueId, fulfillmentCenterId) => {
    const { session } = await fetchActiveSession(venueId, fulfillmentCenterId);
    await updateSession(fulfillmentCenterId, session.acceptingOrders, null);
  };

  const markItemAsSoldOut = (venueId, fulfillmentCenterId, itemId, soldOut: boolean) =>
    authorizedPut(
      'api',
      `/bartender/v3/venues/${venueId}/fulfillment-centers/${fulfillmentCenterId}/menus/1/drinks/${itemId}`,
      {
        soldOut,
      }
    );
  const markOptionAsSoldOut = (venueId, fulfillmentCenterId, drinkOptionId, soldOut: boolean) =>
    authorizedPut(
      'api',
      `/bartender/v3/venues/${venueId}/fulfillment-centers/${fulfillmentCenterId}/menus/1/options/${drinkOptionId}`,
      {
        soldOut,
      }
    );

  const getSessions = (venueId, fulfillmentCenterId) =>
    authorizedRequest('api', `${ADMIN_PREFIX}/venues/${venueId}/fulfillment-centers/${fulfillmentCenterId}/sessions`);
  const getLastReportForSession = (venueId, session_id) =>
    authorizedRequest('api', `${ADMIN_PREFIX}/venues/${venueId}/sessions/${session_id}`);
  const getOrdersForSession = (venueId, session_id) =>
    authorizedRequest('api', `${ADMIN_PREFIX}/venues/${venueId}/sessions/${session_id}/orders`);
  const sendSessionEmail = (venueId, fufillmentCenterId, sessionId, emails) =>
    authorizedPost(
      'api',
      `${ADMIN_PREFIX}/venues/${venueId}/fulfillment-centers/${fufillmentCenterId}/sessions/${sessionId}/send-email`,
      { emails }
    );
  const openVenue = (venue_id, fulfillment_center_id) =>
    authorizedPost('api', `${ADMIN_PREFIX}/venues/${venue_id}/open/${fulfillment_center_id}`, {});
  const closeVenue = (venue_id, fulfillment_center_id) =>
    authorizedPost('api', `${ADMIN_PREFIX}/venues/${venue_id}/close/${fulfillment_center_id}`, {});
  const sendVenueInvite = (data) => authorizedPost('api', `${ADMIN_PREFIX}/venues/send-invite`, data);
  const createBraintreeMerchant = (body) => authorizedPost('api', `${ADMIN_PREFIX}/venues/braintree-merchant`, body);

  /*
    Menu
  */

  const getMenus = () => authorizedRequest('api', `${ADMIN_PREFIX}/menus`);
  const getMenu = (id) => authorizedRequest('api', `${ADMIN_PREFIX}/menus/${id}`);
  const createMenu = (data) => authorizedPost('api', `${ADMIN_PREFIX}/menus`, data);
  const updateMenu = (id, data) => authorizedPut('api', `${ADMIN_PREFIX}/menus/${id}`, data);

  const updateMenuItem = (menuId, drink_id, data) =>
    authorizedPut('api', `/management/v2/menus/${menuId}/drinks/${drink_id}`, data);
  const deleteMenuItem = (menuId, drink_id) =>
    authorizedDelete('api', `/management/v2/menus/${menuId}/drinks/${drink_id}`);

  /*
    Promo Codes
  */

  const getPromoCodes = () => authorizedRequest('api', `${ADMIN_PREFIX}/promo-codes/`);
  const getPromoCode = (id) => authorizedRequest('api', `${ADMIN_PREFIX}/promo-codes/${id}`);
  const createPromoCode = (data) => authorizedPost('api', `${ADMIN_PREFIX}/promo-codes`, data);
  const updatePromoCode = (id, data) => authorizedPut('api', `${ADMIN_PREFIX}/promo-codes/${id}`, data);

  /*
    Users
  */

  const searchUsers = (query) =>
    authorizedRequest('api', `${ADMIN_PREFIX}/users/search?query=${encodeURIComponent(query)}`);
  const getUser = (id) => authorizedRequest('api', `${ADMIN_PREFIX}/users/${id}`);
  const updateUser = (id, data) => authorizedPut('api', `${ADMIN_PREFIX}/users/${id}`, data);
  const deleteUser = (id) => authorizedDelete('api', `${ADMIN_PREFIX}/users/${id}`);
  const unbanUser = (id) => authorizedPost('api', `${ADMIN_PREFIX}/users/${id}/unban`);
  const sendUserInvite = (data) => authorizedPost('api', `${ADMIN_PREFIX}/users/invite`, data);
  const createPasswordResetLink = (id) => authorizedPost('api', `${ADMIN_PREFIX}/users/${id}/reset-password`);

  /*
    Venue Operators
  */

  const getVenueOperators = async () => {
    const response = await authorizedRequest('api', `${ADMIN_PREFIX}/venue-operators`);
    return response.sort(byName);
  };

  const getVenueOperator = (id) => authorizedRequest('api', `${ADMIN_PREFIX}/venue-operators/${id}`);
  const createVenueOperator = (data) => authorizedPost('api', `${ADMIN_PREFIX}/venue-operators`, data);
  const updateVenueOperator = (id, data) => authorizedPut('api', `${ADMIN_PREFIX}/venue-operators/${id}`, data);

  /*
    Categories
  */

  // const getCategories = () => authorizedRequest('api', `${ADMIN_PREFIX}/categories`);
  /*
    Sales Categories
  */

  const getSalesCategories = () => authorizedRequest('api', `${ADMIN_PREFIX}/sales-category`);

  /*
    Option Groups
  */

  const getOptionGroups = () => authorizedRequest('api', `${ADMIN_PREFIX}/option-group`);
  const getOptionGroup = (id) => authorizedRequest('api', `${ADMIN_PREFIX}/option-group/${id}`);
  const createOptionGroup = (data) => authorizedPost('api', `${ADMIN_PREFIX}/option-group`, data);
  const updateOptionGroup = (id, data) => authorizedPut('api', `${ADMIN_PREFIX}/option-group/${id}`, data);

  /*
    Drinks
  */

  const getDrinks = () => authorizedRequest('api', `${ADMIN_PREFIX}/drinks`);
  const getDrink = (id) => authorizedRequest('api', `${ADMIN_PREFIX}/drinks/${id}`);
  const mergeDrink = (baseId, mergeDrinkIds) =>
    authorizedPost('api', `${ADMIN_PREFIX}/drinks/${baseId}/merge`, { mergeDrinkIds });
  const createDrink = (data) => authorizedPost('api', `${ADMIN_PREFIX}/drinks`, data);
  const updateDrink = (id, data) => authorizedPut('api', `${ADMIN_PREFIX}/drinks/${id}`, data);
  const deleteDrink = (id) => authorizedDelete('api', `${ADMIN_PREFIX}/drinks/${id}`);

  /*
    Drink Options
  */

  const getDrinkOptions = () => authorizedRequest('api', `${ADMIN_PREFIX}/drink-options`);
  const getDrinkOption = (id) => authorizedRequest('api', `${ADMIN_PREFIX}/drink-options/${id}`);
  const createDrinkOption = (data) => authorizedPost('api', `${ADMIN_PREFIX}/drink-options`, data);
  const updateDrinkOption = (id, data) => authorizedPut('api', `${ADMIN_PREFIX}/drink-options/${id}`, data);
  const deleteDrinkOption = (id) => authorizedDelete('api', `${ADMIN_PREFIX}/drink-options/${id}`);

  /*
    Features
  */
  const getFeatures = () => authorizedRequest('api', `${ADMIN_PREFIX}/features`);
  const getFeature = (id) => authorizedRequest('api', `${ADMIN_PREFIX}/features/${id}`);
  const createFeature = (data) => authorizedPost('api', `${ADMIN_PREFIX}/features`, data);

  /*
    Menu Styles
  */
  const getMenuStyles = () => authorizedRequest('api', `${ADMIN_PREFIX}/menu-styles`);
  const getMenuStyle = (id) => authorizedRequest('api', `${ADMIN_PREFIX}/menu-styles/${id}`);
  const createMenuStyle = (data) => authorizedPost('api', `${ADMIN_PREFIX}/menu-styles`, data);
  const updateMenuStyle = (id, data) => authorizedPut('api', `${ADMIN_PREFIX}/menu-styles/${id}`, data);

  /*
    Fulfillment Centers
  */
  const getFulfillmentCenter = (venueId, id) =>
    authorizedRequest('api', `${ADMIN_PREFIX}/venues/${venueId}/fulfillment-centers/${id}`);
  const createFulfillmentCenter = (venueId, data) =>
    authorizedPost('api', `${ADMIN_PREFIX}/venues/${venueId}/fulfillment-centers`, data);
  const updateFulfillmentCenter = (venueId, id, data) =>
    authorizedPut('api', `${ADMIN_PREFIX}/venues/${venueId}/fulfillment-centers/${id}`, data);
  const undeleteFulfillmentCenter = (venueId, id) =>
    authorizedPost('api', `${ADMIN_PREFIX}/venues/${venueId}/fulfillment-centers/${id}/undelete`);
  const testDeliveryFormat = (venueId, fulfillmentCenterId, data) =>
    authorizedPost(
      'api',
      `${ADMIN_PREFIX}/venues/${venueId}/fulfillment-centers/${fulfillmentCenterId}/test-delivery`,
      data
    );

  const getFulfillmentCenterMenu = (venueId, id) =>
    authorizedRequest('api', `${ADMIN_PREFIX}/venues/${venueId}/fulfillment-centers/${id}/menu`);

  const getVenueTimeSlots = (venueId) => authorizedRequest('api', `${ADMIN_PREFIX}/venues/${venueId}/time-slots`);

  const getFulfillmentCenterTimeSlots = (fulfillmentCenterId) =>
    authorizedRequest('api', `${ADMIN_PREFIX}/fulfillment-centers/${fulfillmentCenterId}/time-slots`);

  const getTimeSlot = (timeSlotId) =>
    authorizedRequest('api', `${ADMIN_PREFIX}/fulfillment-centers/nan/time-slots/${timeSlotId}`);

  const createFulfillmentCenterTimeSlot = (fulfillmentCenterId, data) =>
    authorizedPost('api', `${ADMIN_PREFIX}/fulfillment-centers/${fulfillmentCenterId}/time-slots`, data);

  const updateFulfillmentCenterTimeSlot = (fulfillmentCenterId, timeSlotId, data) =>
    authorizedPut('api', `${ADMIN_PREFIX}/fulfillment-centers/${fulfillmentCenterId}/time-slots/${timeSlotId}`, data);

  /*
    Reject Reasons
  */
  const getRejectReasons = () => authorizedRequest('api', `${ADMIN_PREFIX}/reject-reasons`);
  const getRejectReason = (id) => authorizedRequest('api', `${ADMIN_PREFIX}/reject-reasons/${id}`);
  const createRejectReason = (data) => authorizedPost('api', `${ADMIN_PREFIX}/reject-reasons`, data);
  const updateRejectReason = (id, data) => authorizedPut('api', `${ADMIN_PREFIX}/reject-reasons/${id}`, data);

  /*
    Tenants
  */
  const fetchTenants = () => posApi.getTenants();
  const fetchTenant = (id: string) => posApi.getTenant({ id });
  const createTenant = (tenant: Tenant) => posApi.createTenant({ tenant });

  /*
    Integration Stack
  */
  const getIntegrationStacks = () => posApi.getIntegrationStacks();
  const getIntegrationStack = (id) => posApi.getIntegrationStack({ integrationStackId: id });
  const createIntegrationStack = (data: IntegrationStack) => posApi.createIntegrationStack({ integrationStack: data });
  const updateIntegrationStack = (id, data: IntegrationStack) =>
    posApi.updateIntegrationStack({ integrationStackId: id, integrationStackUpdateBody: data });
  const fetchSyncingConfigsForIntegrationStack = (id) =>
    posApi.getIntegrationStackSyncConfigs({ integrationStackId: id });
  const getTenants = () => posApi.getTenants();
  const getNextgenConfigs = () => posApi.managementV1NextgenConfigsGet();
  const getVerifoneSca = () => posApi.managementV1VerifoneScaGet();
  const getSquareMerchants = () => posApi.managementV1SquareMerchantsGet();

  /*
    Clover Sport
  */
  const getCloverSportConfigs = () => posApi.managementV1CloverSportConfigsGet();
  const getCloverSportConfig = (id: string) => posApi.managementV1CloverSportConfigsIdGet({ id });
  const createCloverSportConfig = (cloverSportConfig: ManagementV1CloverSportConfigsPostRequest['cloverSportConfig']) =>
    posApi.managementV1CloverSportConfigsPost({
      cloverSportConfig,
    });

  const updateCloverSportConfig = (
    id: string,
    cloverSportConfig: ManagementV1CloverSportConfigsIdPutRequest['cloverSportConfig']
  ) =>
    posApi.managementV1CloverSportConfigsIdPut({
      id,
      cloverSportConfig,
    });

  /*
    Clover Flex
  */
  const getCloverFlexConfigs = () => posApi.managementV1CloverFlexConfigsGet();
  const getCloverFlexConfig = (id: string) => posApi.managementV1CloverFlexConfigsIdGet({ id });
  const createCloverFlexConfig = (cloverFlexConfig: ManagementV1CloverFlexConfigsPostRequest['cloverFlexConfig']) =>
    posApi.managementV1CloverFlexConfigsPost({
      cloverFlexConfig,
    });

  const updateCloverFlexConfig = (
    id: string,
    cloverFlexConfig: ManagementV1CloverFlexConfigsIdPutRequest['cloverFlexConfig']
  ) =>
    posApi.managementV1CloverFlexConfigsIdPut({
      id,
      cloverFlexConfig,
    });

  /*
    Sync Config
  */
  const getSyncConfigs = () => posApi.getSyncConfigs();
  const getSyncConfig = (id) => posApi.getSyncConfig({ syncConfigId: id });
  const createSyncConfig = (integrationStackId: string, syncConfigViewModel: SyncConfigViewModel) =>
    posApi.createSyncConfig({ integrationStackId, syncConfigViewModel });
  const updateSyncConfig = (id, syncConfigViewModel: SyncConfigViewModel) =>
    posApi.updateSyncConfig({ syncConfigId: id, syncConfigViewModel });
  const getSyncConfigRecords = (syncConfigId) => posApi.getSyncRecordsForConfig({ syncConfigId });

  const queueSyncConfig = (id) => posApi.queueSyncConfigSync({ syncConfigId: id });
  const getSyncRecordNormalizedMenu = (syncRecordId) => posApi.getNormalizedDataUrlForSyncRecord({ syncRecordId });
  const getSyncRecordRawMenu = (syncRecordId) => posApi.getRawDataUrlForSyncRecord({ syncRecordId });

  const createVenueSyncConfig = (nobleVenueSyncConfig: NobleVenueSyncConfig) =>
    posApi.createVenueSyncConfig({ nobleVenueSyncConfig });

  /*
    Roles
  */
  const getRoles = () => authorizedRequest('api', `${ADMIN_PREFIX}/roles`);
  const getRole = (id) => authorizedRequest('api', `${ADMIN_PREFIX}/roles/${id}`);
  const createRole = (data) => authorizedPost('api', `${ADMIN_PREFIX}/roles`, data);
  const updateRole = (id, data) => authorizedPut('api', `${ADMIN_PREFIX}/roles/${id}`, data);

  /*
    Freedom Pay Config
  */
  const getFreedomPayConfigs = () => authorizedRequest('api', `${ADMIN_PREFIX}/freedom-pay-config`);
  const getFreedomPayConfig = (id) => authorizedRequest('api', `${ADMIN_PREFIX}/freedom-pay-config/${id}`);
  const createFreedomPayConfig = (data) => authorizedPost('api', `${ADMIN_PREFIX}/freedom-pay-config`, data);
  const updateFreedomPayConfig = (id, data) => authorizedPut('api', `${ADMIN_PREFIX}/freedom-pay-config/${id}`, data);

  /*
    Brand Split Payment Methods
  */
  const getBrandSplitPaymentMethods = () => authorizedRequest('api', `${ADMIN_PREFIX}/brand-split-payment-method`);
  const getBrandSplitPaymentMethod = (id) =>
    authorizedRequest('api', `${ADMIN_PREFIX}/brand-split-payment-method/${id}`);
  const createBrandSplitPaymentMethod = (data) =>
    authorizedPost('api', `${ADMIN_PREFIX}/brand-split-payment-method`, data);
  const updateBrandSplitPaymentMethod = (id, data) =>
    authorizedPut('api', `${ADMIN_PREFIX}/brand-split-payment-method/${id}`, data);

  /*
    Freedom Pay Config
  */
  const getTextmunicationConfigs = () => authorizedRequest('api', `${ADMIN_PREFIX}/textmunication-configuration`);
  const getTextmunicationConfig = (id) =>
    authorizedRequest('api', `${ADMIN_PREFIX}/textmunication-configuration/${id}`);
  const createTextmunicationConfig = (data) =>
    authorizedPost('api', `${ADMIN_PREFIX}/textmunication-configuration`, data);
  const updateTextmunicationConfig = (id, data) =>
    authorizedPut('api', `${ADMIN_PREFIX}/textmunication-configuration/${id}`, data);

  const setupTextmunicationForVenue = (venueId) =>
    authorizedPost('api', `${ADMIN_PREFIX}/venues/${venueId}/setup-textmunication`);

  /*
    Venue Operators
  */

  const getPickupLocations = (venueId, fulfillmentCenterId) =>
    authorizedRequest(
      'api',
      `${ADMIN_PREFIX}/venues/${venueId}/fulfillment-centers/${fulfillmentCenterId}/pickup-locations`
    );
  const getPickupLocation = (venueId, fulfillmentCenterId, id) =>
    authorizedRequest(
      'api',
      `${ADMIN_PREFIX}/venues/${venueId}/fulfillment-centers/${fulfillmentCenterId}/pickup-locations/${id}`
    );
  const createPickupLocation = (venueId, fulfillmentCenterId, data) =>
    authorizedPost(
      'api',
      `${ADMIN_PREFIX}/venues/${venueId}/fulfillment-centers/${fulfillmentCenterId}/pickup-locations`,
      data
    );
  const updatePickupLocation = (venueId, fulfillmentCenterId, id, data) =>
    authorizedPut(
      'api',
      `${ADMIN_PREFIX}/venues/${venueId}/fulfillment-centers/${fulfillmentCenterId}/pickup-locations/${id}`,
      data
    );
  const deletePickupLocation = (venueId, fulfillmentCenterId, id) =>
    authorizedDelete(
      'api',
      `${ADMIN_PREFIX}/venues/${venueId}/fulfillment-centers/${fulfillmentCenterId}/pickup-locations/${id}`
    );

  /*
    Orders
  */
  const sendReminderPush = (orderId) => authorizedPost('api', `${ADMIN_PREFIX}/orders/${orderId}/send-reminder-push`);
  const getOrders = (venueId, fulfillmentCenterId, status, page, sortField) =>
    authorizedRequest(
      'api',
      `${ADMIN_PREFIX}/venues/${venueId}/orders/fulfillment-center/${fulfillmentCenterId}/status/${status}?page=${page}&sortField=${sortField}`
    );
  const getOrder = (orderId) => authorizedRequest('api', `${ADMIN_PREFIX}/orders/${orderId}`);
  const updateOrder = (venueId, fulfillmentCenterId, ordersId, status) =>
    authorizedPost('api', `${ADMIN_PREFIX}/venues/${venueId}/orders/fulfillment-center/${fulfillmentCenterId}`, {
      ordersId,
      status,
    });
  const rejectOrder = (orderId, rejectReasonId, sendNotification) =>
    authorizedPost('api', `${ADMIN_PREFIX}/orders/${orderId}/reject`, {
      rejectReasonId,
      sendNotification,
    });
  const openScheduleOrder = (venueId, fulfillmentCenterId, orderIds) =>
    authorizedPost('api', `${ADMIN_PREFIX}/venues/${venueId}/orders/fulfillment-center/${fulfillmentCenterId}/open`, {
      orderIds,
    });
  const searchOrders = (query) => authorizedPost('api', `${ADMIN_PREFIX}/orders/search`, query);
  const refundOrder = (id, data) => authorizedPost('api', `${ADMIN_PREFIX}/orders/${id}/refund`, data);
  const changeOrderUser = (orderId, userId) =>
    authorizedPut('api', `${ADMIN_PREFIX}/orders/${orderId}/update-user`, { userId });
  const updateOrderInternally = (orderId, body) => authorizedPut('api', `${ADMIN_PREFIX}/orders/${orderId}`, body);
  const confirmOrder = (orderId) => authorizedPost('api', `${ADMIN_PREFIX}/orders/${orderId}/confirm`, {});
  const resendReceipt = (orderId, overrideEmail) =>
    authorizedPost('api', `${ADMIN_PREFIX}/orders/${orderId}/resend-reciept`, {
      overrideEmail,
    });

  /*
    Preorder Reminders
  */
  const sendPreorderReminders = (data) => authorizedPost('api', `${ADMIN_PREFIX}/preorder-reminders/send`, data);
  const fetchPreorderReminderList = (start, end) => {
    debugger;
    return authorizedRequest('api', `${ADMIN_PREFIX}/preorder-reminders?start=${start}&end=${end}`);
  };
  const sendPreorderReminderEmail = (data) =>
    authorizedPost('api', `${ADMIN_PREFIX}/preorder-reminders/send-confirmation`, data);
  const fetchPreorderList = (data) =>
    authorizedPost('api', `${ADMIN_PREFIX}/preorder-reminders/send-confirmation-response`, data);
  /*
    Delivery Locations
  */
  const getDeliveryLocationsRoot = (fulfillmentCenterId) =>
    authorizedRequest('api', `${ADMIN_PREFIX}/fulfillment-centers/${fulfillmentCenterId}/delivery-locations`);
  const getDeliveryLocationsForCategoryId = (fulfillmentCenterId, parentCategoryId, parentLocationId) =>
    authorizedRequest(
      'api',
      `${ADMIN_PREFIX}/fulfillment-centers/${fulfillmentCenterId}/delivery-locations/${parentCategoryId}/${parentLocationId}`
    );
  const createDeliveryCategory = (fulfillmentCenterId, data) =>
    authorizedPost('api', `${ADMIN_PREFIX}/fulfillment-centers/${fulfillmentCenterId}/delivery-categories`, data);
  const saveLocations = (fulfillmentCenterId, categoryId, parentLocationId, data) => {
    if (!parentLocationId) {
      return authorizedPost(
        'api',
        `${ADMIN_PREFIX}/fulfillment-centers/${fulfillmentCenterId}/delivery-categories/${categoryId}/delivery-locations`,
        data
      );
    } else {
      return authorizedPost(
        'api',
        `${ADMIN_PREFIX}/fulfillment-centers/${fulfillmentCenterId}/delivery-categories/${categoryId}/delivery-locations/${parentLocationId}`,
        data
      );
    }
  };

  /*
    Product Updates
  */
  const getProductUpdates = () => authorizedRequest('api', `${ADMIN_PREFIX}/product-update`);
  const getProductUpdate = (id) => authorizedRequest('api', `${ADMIN_PREFIX}/product-update/${id}`);
  const createProductUpdate = (data) => authorizedPost('api', `${ADMIN_PREFIX}/product-update`, data);
  const updateProductUpdate = (id, data) => authorizedPut('api', `${ADMIN_PREFIX}/product-update/${id}`, data);

  /*
    Delivery Configurations
  */
  const getDeliveryConfigurations = ({ venueId }) =>
    authorizedRequest('api', `${ADMIN_PREFIX}/venue-delivery-configuration?venueId=${venueId}`);
  const getDeliveryConfiguration = (id) =>
    authorizedRequest('api', `${ADMIN_PREFIX}/venue-delivery-configuration/${id}`);
  const createDeliveryConfiguration = (data) =>
    authorizedPost('api', `${ADMIN_PREFIX}/venue-delivery-configuration`, data);
  const updateDeliveryConfiguration = (id, data) =>
    authorizedPut('api', `${ADMIN_PREFIX}/venue-delivery-configuration/${id}`, data);

  /*
    Partners
  */
  const getPartners = () => authorizedRequest('api', `${ADMIN_PREFIX}/partner`);
  const getPartner = (id) => authorizedRequest('api', `${ADMIN_PREFIX}/partner/${id}`);
  const createPartner = (data) => authorizedPost('api', `${ADMIN_PREFIX}/partner`, data);
  const updatePartner = (id, data) => authorizedPut('api', `${ADMIN_PREFIX}/partner/${id}`, data);

  const getPartnerLocationProxyGroups = () => authorizedRequest('api', `${ADMIN_PREFIX}/partner-location-proxy-group`);
  const getPartnerLocationProxyGroup = (id) =>
    authorizedRequest('api', `${ADMIN_PREFIX}/partner-location-proxy-group/${id}`);
  const createPartnerLocationProxyGroup = (data) =>
    authorizedPost('api', `${ADMIN_PREFIX}/partner-location-proxy-group`, data);
  const updatePartnerLocationProxyGroup = (id, data) =>
    authorizedPut('api', `${ADMIN_PREFIX}/partner-location-proxy-group/${id}`, data);

  const getPartnerLocationProxys = (groupId?: string | undefined) => {
    const query = new URLSearchParams();
    if (groupId) {
      query.append('groupId', groupId);
    }

    return authorizedRequest('api', `${ADMIN_PREFIX}/partner-location-proxy?` + query.toString());
  };
  const getPartnerLocationProxy = (id) => authorizedRequest('api', `${ADMIN_PREFIX}/partner-location-proxy/${id}`);
  const createPartnerLocationProxy = (data) => authorizedPost('api', `${ADMIN_PREFIX}/partner-location-proxy`, data);
  const updatePartnerLocationProxy = (id, data) =>
    authorizedPut('api', `${ADMIN_PREFIX}/partner-location-proxy/${id}`, data);
  const deletePartnerLocationProxy = (id) => authorizedDelete('api', `${ADMIN_PREFIX}/partner-location-proxy/${id}`);

  // Partner Endpoints
  const getPartnerLocationMenu = (token, locationId) =>
    authorizedRequest('api', `/partners/v2/locations/${locationId}/menu`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

  // POS
  const fetchConfigOptions = (venue) => authorizedPost('internal', `/pos/config`, { venue });

  // GEM
  const fetchGEMToken = (venueId) => authorizedPost('api', `${ADMIN_PREFIX}/venues/${venueId}/gem-token`, {});

  return {
    API_URL,
    POS_API_URL,
    authorizedPost,
    upload,
    privateUpload,
    sendNotification,
    getVenues,
    getSyncDataType,
    getActiveVenues,
    getVenue,
    createVenue,
    updateVenue,
    deleteVenue,
    getBraintreeMerchantStatus,
    getAllBraintreeMerchantStatuses,
    updateSession,
    fetchActiveSession,
    removeDeviceId,
    markItemAsSoldOut,
    markOptionAsSoldOut,
    getSessions,
    getLastReportForSession,
    getOrdersForSession,
    sendSessionEmail,
    openVenue,
    closeVenue,
    sendVenueInvite,
    createBraintreeMerchant,
    getMenus,
    getMenu,
    createMenu,
    updateMenu,
    updateMenuItem,
    deleteMenuItem,
    getPromoCodes,
    getPromoCode,
    createPromoCode,
    updatePromoCode,
    searchUsers,
    getUser,
    updateUser,
    deleteUser,
    unbanUser,
    sendUserInvite,
    createPasswordResetLink,
    getVenueOperators,
    getVenueOperator,
    createVenueOperator,
    updateVenueOperator,
    getSalesCategories,
    getOptionGroups,
    getOptionGroup,
    createOptionGroup,
    updateOptionGroup,
    getDrinks,
    getDrink,
    mergeDrink,
    createDrink,
    updateDrink,
    deleteDrink,
    getDrinkOptions,
    getDrinkOption,
    createDrinkOption,
    updateDrinkOption,
    deleteDrinkOption,
    getFeatures,
    getFeature,
    createFeature,
    getMenuStyles,
    getMenuStyle,
    createMenuStyle,
    updateMenuStyle,
    getFulfillmentCenter,
    createFulfillmentCenter,
    updateFulfillmentCenter,
    undeleteFulfillmentCenter,
    testDeliveryFormat,
    getFulfillmentCenterMenu,
    getRejectReasons,
    getRejectReason,
    createRejectReason,
    updateRejectReason,
    fetchTenants,
    fetchTenant,
    createTenant,
    getIntegrationStacks,
    getIntegrationStack,
    createIntegrationStack,
    updateIntegrationStack,
    fetchSyncingConfigsForIntegrationStack,
    getTenants,
    getNextgenConfigs,
    getVerifoneSca,
    getSquareMerchants,
    getCloverSportConfigs,
    getCloverSportConfig,
    createCloverSportConfig,
    updateCloverSportConfig,
    getSyncConfigs,
    getSyncConfig,
    createSyncConfig,
    updateSyncConfig,
    getSyncConfigRecords,
    queueSyncConfig,
    getSyncRecordNormalizedMenu,
    getSyncRecordRawMenu,
    createVenueSyncConfig,
    getRoles,
    getRole,
    createRole,
    updateRole,
    getFreedomPayConfigs,
    getFreedomPayConfig,
    createFreedomPayConfig,
    updateFreedomPayConfig,
    getBrandSplitPaymentMethods,
    getBrandSplitPaymentMethod,
    createBrandSplitPaymentMethod,
    updateBrandSplitPaymentMethod,
    getTextmunicationConfigs,
    getTextmunicationConfig,
    createTextmunicationConfig,
    updateTextmunicationConfig,
    setupTextmunicationForVenue,
    getPickupLocations,
    getPickupLocation,
    createPickupLocation,
    updatePickupLocation,
    deletePickupLocation,
    sendReminderPush,
    getOrders,
    getOrder,
    updateOrder,
    rejectOrder,
    openScheduleOrder,
    searchOrders,
    refundOrder,
    changeOrderUser,
    updateOrderInternally,
    confirmOrder,
    resendReceipt,
    sendPreorderReminders,
    fetchPreorderReminderList,
    sendPreorderReminderEmail,
    fetchPreorderList,
    getDeliveryLocationsRoot,
    getDeliveryLocationsForCategoryId,
    createDeliveryCategory,
    saveLocations,
    getProductUpdates,
    getProductUpdate,
    createProductUpdate,
    updateProductUpdate,
    getDeliveryConfigurations,
    getDeliveryConfiguration,
    createDeliveryConfiguration,
    updateDeliveryConfiguration,
    getPartners,
    getPartner,
    createPartner,
    updatePartner,
    getPartnerLocationProxyGroups,
    getPartnerLocationProxyGroup,
    createPartnerLocationProxyGroup,
    updatePartnerLocationProxyGroup,
    getPartnerLocationProxys,
    getPartnerLocationProxy,
    createPartnerLocationProxy,
    updatePartnerLocationProxy,
    deletePartnerLocationProxy,
    getPartnerLocationMenu,
    fetchConfigOptions,
    fetchGEMToken,
    getVenueTimeSlots,
    getFulfillmentCenterTimeSlots,
    createFulfillmentCenterTimeSlot,
    updateFulfillmentCenterTimeSlot,
    getTimeSlot,
    getCloverFlexConfigs,
    getCloverFlexConfig,
    createCloverFlexConfig,
    updateCloverFlexConfig,
  };
};

export type MgmtAPI = ReturnType<typeof createApi>;

const getToken = () => {
  const cookies = new Cookies();
  const token = cookies.get('access_token');
  return token;
};
export const api = createApi(getToken);

export const useApi = () => {
  const authContext = React.useContext(AuthContext);
  const api = createApi(authContext?.getAccessToken ?? getToken);
  return api;
};

export type InjectedAPIProps = { api: MgmtAPI };
export const withAPI = <P extends { api: MgmtAPI }>(Component: React.ComponentType<P>) => {
  const Wrapped: React.FC<P> = (props) => {
    const api = useApi();
    return <Component {...props} api={api} />;
  };

  return Wrapped;
};
