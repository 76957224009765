import { Form } from '../../components';
import * as React from 'react';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { JSONSchema7 } from 'json-schema';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
let Editor;
if (process.env.BUILD_TARGET === 'client') {
  Editor = require('react-draft-wysiwyg');
}

interface ProductUpdateFomrProps {
  onSubmit: Function;
  loading: boolean;
  update: any;
}

const schema: JSONSchema7 = {
  properties: {
    releaseNotes: {
      type: 'string',
    },
    releaseDate: {
      type: 'string',
    },
  },
  required: ['releaseNotes', 'releaseDate'],
  type: 'object',
};

const uiSchema = {
  releaseDate: {
    'ui:widget': 'alt-datetime',
  },
  releaseNotes: {
    'ui:field': 'wysiwyg',
  },
};

class WysiwygField extends React.Component<any, any> {
  constructor(props) {
    super(props);

    if (process.env.BUILD_TARGET === 'client') {
      const blocksFromHTML = convertFromHTML(props.formData || '');
      const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
      this.state = {
        editorState: EditorState.createWithContent(contentState),
      };
    }
  }

  onEditorStateChange = (editorState) => {
    this.setState(
      {
        editorState,
      },
      () => {
        this.props.onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
      }
    );
  };

  render() {
    if (!Editor) {
      return null;
    }

    const { editorState } = this.state;
    return (
      <div style={{ border: '1px solid #000' }}>
        <Editor.Editor editorState={editorState} onEditorStateChange={this.onEditorStateChange} />
      </div>
    );
  }
}

const fields = { wysiwyg: WysiwygField };

export class ProductUpdateForm extends React.Component<ProductUpdateFomrProps, any> {
  onSubmit = ({ formData }) => {
    this.props.onSubmit(formData);
  };

  render() {
    if (this.props.loading) {
      return <div>Loading...</div>;
    }

    return (
      <div className='w-100'>
        <Form
          uiSchema={uiSchema}
          fields={fields}
          formData={this.props.update}
          schema={schema}
          onSubmit={this.onSubmit}
        />
      </div>
    );
  }
}
