import * as React from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { api } from '../../API';
import { DataTable } from '../../components/DataTable';

export class RoleList extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = { roles: [], loading: true };
  }

  fetch = async () => {
    const roles = await api.getRoles();
    this.setState({
      loading: false,
      roles,
    });
  };

  componentDidMount() {
    this.fetch();
  }

  render() {
    if (this.state.loading) {
      return <div>Loading...</div>;
    } else {
      const { roles } = this.state;

      return (
        <Card className='w-100'>
          <CardHeader>Roles</CardHeader>
          <CardBody>
            <DataTable
              headers={['Reason', 'Actions']}
              rows={roles.map((role) => [
                role.name,
                <Button key='btn' tag='a' href={`/roles/edit/${role.id}`} color='success'>
                  Edit
                </Button>,
              ])}
            />
            <div className='text-center'>
              <Button tag='a' href={`/roles/add`} color='success'>
                Add
              </Button>
            </div>
          </CardBody>
        </Card>
      );
    }
  }
}
