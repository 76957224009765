import * as React from 'react';
import { Form } from '../../components';
import { api } from '../../API';
import { JSONSchema7 } from 'json-schema';

const schema: JSONSchema7 = {
  properties: {
    integrationStackId: {
      type: 'string',
      title: 'Integration Stack ID',
    },
    name: {
      type: 'string',
      title: 'Name',
    },
    syncDataType: {
      type: 'string',
      title: 'Sync Data Type',
    },
    menuSyncConfig: {
      type: 'string',
      title: 'Menu Sync Config',
    },
    syncFrequencyMinutes: {
      type: 'integer',
      title: 'Sync Frequency (minutes)',
    },
    isActive: {
      type: 'boolean',
      title: 'Is Active',
    },
  },
  required: ['integrationStackId', 'syncDataType', 'isActive'],
  type: 'object',
};

const uiSchema = {
  isActive: {
    'ui:widget': 'radio',
  },
  integrationStackId: {
    'ui:widget': 'entity',
    'ui:options': {
      label: true,
      load: api.getIntegrationStacks,
      process: (venues) =>
        venues.map((venue) => ({
          value: venue.id,
          name: venue.name,
        })),
    },
  },
  syncDataType: {
    'ui:widget': 'entity',
    'ui:options': {
      label: true,
      load: api.getSyncDataType,
      process: (venues) =>
        venues.map((venue) => ({
          value: venue.value,
          name: venue.name,
        })),
    },
  },
  menuSyncConfig: {
    'ui:widget': 'textarea',
  },
};

export class SyncConfigForm extends React.Component<any, any> {
  public onSubmit = ({ formData }) => {
    if (formData.menuSyncConfig) {
      formData.menuSyncConfig = JSON.parse(formData.menuSyncConfig);
    }

    if (!formData.syncFrequencyMinutes) {
      formData.syncFrequencyMinutes = null;
    }

    this.props.onSubmit(formData);
  };

  render() {
    return (
      <div className='w-100'>
        <Form formData={this.props.syncConfig} uiSchema={uiSchema} schema={schema} onSubmit={this.onSubmit} />
      </div>
    );
  }
}
