import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { api } from '../../API';
import { RoleForm } from './Form';

export class AddRole extends React.Component<RouteComponentProps, any> {
  handleSubmit = async (data) => {
    await api.createRole(data);
    this.props.history.push('/roles/list');
  };

  render() {
    return <RoleForm add={true} onSubmit={this.handleSubmit} />;
  }
}
