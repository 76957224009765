import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { api } from '../../API';
import { DataTable } from '../../components/DataTable';

interface EditPickupLocationRouterParams {
  venueId: string;
  fulfillmentCenterId: string;
  id: string;
}

interface PickupLocationState {
  pickupLocations: any[];
  loading: boolean;
}

type PickupLocationsProps = RouteComponentProps<EditPickupLocationRouterParams>;

const getEditUrl = (venueId, fulfillmentCenterId, id) => {
  return `/venues/${venueId}/fulfillment-centers/${fulfillmentCenterId}/pickup-locations/edit/${id}`;
};

const getAddUrl = (venueId, fulfillmentCenterId) => {
  return `/venues/${venueId}/fulfillment-centers/${fulfillmentCenterId}/pickup-locations/add`;
};

export class PickupLocations extends React.PureComponent<PickupLocationsProps, PickupLocationState> {
  constructor(props) {
    super(props);

    this.state = { pickupLocations: [], loading: true };
  }

  async componentDidMount() {
    const { venueId, fulfillmentCenterId } = this.props.match.params;
    const pickupLocations = await api.getPickupLocations(venueId, fulfillmentCenterId);
    this.setState({ loading: false, pickupLocations });
  }

  public deletePickupLocation = async (id) => {
    this.setState({ loading: true });

    const { venueId, fulfillmentCenterId } = this.props.match.params;
    await api.deletePickupLocation(venueId, fulfillmentCenterId, id);
    const pickupLocations = await api.getPickupLocations(venueId, fulfillmentCenterId);
    this.setState({ loading: false, pickupLocations });
  };

  renderContent() {
    if (this.state.loading) {
      return <div>Loading...</div>;
    } else {
      const { venueId, fulfillmentCenterId } = this.props.match.params;
      return (
        <div>
          <DataTable
            headers={['Name', 'Actions']}
            rows={this.state.pickupLocations.map((pickupLocation) => [
              pickupLocation.name,
              <Button key='edit' tag='a' href={getEditUrl(venueId, fulfillmentCenterId, pickupLocation.id)}>
                Edit
              </Button>,
              <Button key='delete' onClick={this.deletePickupLocation.bind(this, pickupLocation.id)}>
                Delete
              </Button>,
            ])}
          />
          <div className='text-center'>
            <Button className='' size='lg' color='primary' tag='a' href={getAddUrl(venueId, fulfillmentCenterId)}>
              Add
            </Button>
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <Card>
        <CardHeader>Pickup Locations</CardHeader>
        <CardBody>{this.renderContent()}</CardBody>
      </Card>
    );
  }
}
