import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

class EntitySelectMultiple extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = { loading: true, checked: props.formData || [] };
    this.renderRow = this.renderRow.bind(this);

    const options = this.props.uiSchema['ui:options'];

    this.onChange = this.onChange.bind(this);
    options.load().then((results) => {
      this.setState({ loading: false, options: options.process(results) });
    });
  }

  onChange(event) {
    const { value } = event.target;
    const onFinishChange = () => this.props.onChange(this.state.checked);
    if (!this.state.checked.includes(value)) {
      this.setState(
        {
          checked: this.state.checked.concat(value),
        },
        onFinishChange
      );
    } else {
      this.setState(
        {
          checked: this.state.checked.filter((el) => el !== value),
        },
        onFinishChange
      );
    }
  }

  renderRow(option, key) {
    return (
      <FormGroup check={true} key={key}>
        <Label check={true}>
          <Input
            onChange={this.onChange}
            type='checkbox'
            checked={this.state.checked.includes(option.value)}
            value={option.value}
            name={`check-${key}`}
          />{' '}
          {option.name}
        </Label>
      </FormGroup>
    );
  }

  render() {
    if (this.state.loading) {
      return <div>Loading...</div>;
    }

    return (
      <FormGroup tag='fieldset'>
        <Label for='name'>{this.props.schema.title}</Label>
        {this.state.options.map(this.renderRow)}
      </FormGroup>
    );
  }
}

export default EntitySelectMultiple;
