import { user as schema } from '@getnoble/noble-json-schemas';
import * as React from 'react';
import { Form } from '../../components';
import { api } from '../../API';

const uiSchema = {
  bartender: {
    venueId: {
      'ui:widget': 'entity',
      'ui:options': {
        label: true,
        load: api.getVenues,
        process: (venues) => venues.map((venue) => ({ value: venue.id, name: venue.name })),
      },
    },
  },
  operatorManager: {
    venueOperatorId: {
      'ui:widget': 'entity',
      'ui:options': {
        label: true,
        load: api.getVenueOperators,
        process: (operators) =>
          operators.map((operator) => ({
            value: operator.id,
            name: operator.name,
          })),
      },
    },
  },
};

export class UserForm extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { user: props.user };
  }

  render() {
    const onSubmit = (data) => {
      const { formData } = data;
      this.props.onSubmit(formData);
    };

    delete schema.properties.firebaseToken;

    return (
      <div className='w-100'>
        <Form formData={this.state.user} uiSchema={uiSchema} schema={schema} onSubmit={onSubmit} />
      </div>
    );
  }
}
