let config;

if (typeof window !== 'undefined') {
  config = {
    API_ENV: (window as any).env.API_ENV,
    API_URL: (window as any).env.API_URL,
    POS_API_URL: (window as any).env.POS_API_URL,
    VENUE_PORTAL_URL: (window as any).env.VENUE_PORTAL_URL,
    COGNITO_TOKEN_URL: (window as any).env.COGNITO_TOKEN_URL,
  };
} else {
  const apis = {
    local: 'http://localhost:5000',
    development: 'https://api.dev.getnoble.co',
    staging: 'https://staging-api.getnoble.co',
    production: 'https://api.getnoble.co',
  };

  const venuePortal = {
    local: 'http://localhost:5000',
    development: 'https://venue.dev.getnoble.co',
    staging: 'https://venue.stg.getnoble.co',
    production: 'https://venue.getnoble.co',
  };

  const posApi = {
    local: 'http://localhost:8000',
    development: 'https://venue.dev.getnoble.co',
    staging: 'https://venue.stg.getnoble.co',
    production: 'https://venue.getnoble.co',
  };

  const posV2Api = {
    local: 'https://localhost:2258/',
    development: 'https://pos-v2.dev.getnoble.co',
    staging: 'https://pos-v2.stg.getnoble.co',
    production: 'https://pos-v2.getnoble.co',
  };

  const COGNITO_SETTINGS = {
    local: {
      tokenUrl: 'https://noble-dev-api.auth.us-east-1.amazoncognito.com/oauth2/token',
    },
    development: {
      tokenUrl: 'https://noble-dev-api.auth.us-east-1.amazoncognito.com/oauth2/token',
    },
    staging: {
      tokenUrl: 'https://noble-stg-api.auth.us-east-1.amazoncognito.com/oauth2/token',
    },
    production: {
      tokenUrl: 'https://noble-prd-api.auth.us-east-1.amazoncognito.com/oauth2/token',
    },
  };

  const env = process.env.API_ENV || 'local';

  config = {
    API_ENV: env,
    API_URL: apis[env],
    POS_API_URL: posApi[env],
    POS_V2_API_URL: posV2Api[env],
    VENUE_PORTAL_URL: venuePortal[env],
    COGNITO_TOKEN_URL: COGNITO_SETTINGS[env].tokenUrl,
  };
  console.log(config);
}

export default config;
