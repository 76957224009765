import * as React from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { api } from '../../API';
import { DataTable } from '../../components/DataTable';
import { IntegrationStackViewModel } from '@getnoble/pos-service-v2';
import { Link } from 'react-router-dom';
import { paths } from '../../paths';

export class IntegrationStackList extends React.Component<
  {},
  {
    integrationStacks: IntegrationStackViewModel[];
    loading: boolean;
  }
> {
  constructor(props) {
    super(props);

    this.state = { integrationStacks: [], loading: true };
    // this.state = { rejectReasons: [], loading: true };
  }

  fetch = async () => {
    const integrationStacks = await api.getIntegrationStacks();
    this.setState({
      loading: false,
      integrationStacks,
    });
  };

  componentDidMount() {
    this.fetch();
  }

  render() {
    if (this.state.loading) {
      return <div>Loading...</div>;
    } else {
      const { integrationStacks } = this.state;

      return (
        <Card className='w-100'>
          <CardHeader>Integration Stack List</CardHeader>
          <CardBody>
            <DataTable
              headers={['Name', 'Integration Type', 'Actions']}
              rows={integrationStacks.map((reason) => [
                reason.name,
                <div key='Integration Type'>
                  <ul>
                    {reason.squareMerchant?.id && <li>Square</li>}
                    {reason.authorizeNetMerchant?.id && <li>Authorize.Net</li>}
                    {reason.nextgenConnectorConfig?.id && <li>Nextgen Connector</li>}
                    {reason.cloverSportConfig?.id && (
                      <li>
                        <Link
                          to={paths.posv2.cloverSport.edit({
                            integrationStackId: reason.id!,
                            cloverSportConfigId: reason.cloverSportConfig?.id!,
                          })}
                        >
                          Clover Sport
                        </Link>
                      </li>
                    )}
                    {reason.cloverFlexConfig?.id && (
                      <li>
                        <Link
                          to={paths.posv2.cloverFlex.edit({
                            integrationStackId: reason.id!,
                            cloverFlexConfigId: reason.cloverFlexConfig?.id!,
                          })}
                        >
                          Clover Flex
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>,
                <div key='actions'>
                  <Button tag='a' href={`/integration-stack/edit/${reason.id}`} color='success'>
                    Edit
                  </Button>
                  {/* onClick={() => this.syncingConfigs(reason.id)} */}
                  <Button
                    tag='a'
                    style={{ marginLeft: 10 + 'px' }}
                    href={`/sync-config/list/${reason.id}`}
                    color='success'
                  >
                    Syncing Configs
                  </Button>
                </div>,
              ])}
            />
            <div className='text-center'>
              <Button tag='a' href={`/integration-stack/add`} color='success'>
                Add
              </Button>
            </div>
          </CardBody>
        </Card>
      );
    }
  }
}
