import { Form } from '../../components';
import { promoCode as schema } from '@getnoble/noble-json-schemas';
import * as React from 'react';
import { api } from '../../API';

interface PromoCodeFormProps {
  onSubmit: Function;
  loading: boolean;
  code: any;
}

const uiSchema = {
  enabled: {
    'ui:widget': 'radio',
  },
  paymentMethodId: {
    'ui:widget': 'entity',
    'ui:options': {
      label: true,
      load: api.getBrandSplitPaymentMethods,
      process: (paymentMethods) =>
        paymentMethods.map((paymentMethod) => ({
          value: paymentMethod.id,
          name: paymentMethod.name,
        })),
    },
  },
};

export class PromoCodeForm extends React.Component<PromoCodeFormProps, any> {
  onSubmit = ({ formData }) => {
    const final = Object.assign({}, formData);
    if (!final.limit_count) {
      delete final.limit_count;
    }

    this.props.onSubmit(final);
  };

  render() {
    if (this.props.loading) {
      return <div>Loading...</div>;
    }

    if (!this.props.code || !this.props.code.venueId) {
      delete schema.properties.venueId;
    }

    if (!this.props.code || !this.props.code.userId) {
      delete schema.properties.userId;
    } else {
      schema.properties.userId.type = 'string';
    }

    return (
      <div className='w-100'>
        <Form formData={this.props.code} uiSchema={uiSchema} schema={schema} onSubmit={this.onSubmit} />
      </div>
    );
  }
}
