import * as React from 'react';
import { Form } from '../../../components';
import { CloverFlexConfig } from '@getnoble/pos-service-v2';
import { JSONSchema7 } from 'json-schema';

export const cloverFlexConfigSchema: JSONSchema7 = {
  properties: {
    cloverFlexEcommerceBaseUrl: {
      type: 'string',
      title: 'Clover Flex Ecommerce Base Url',
      enum: ['https://scl.clover.com', 'https://scl-sandbox.dev.clover.com'],
    },
    cloverFlexConnectBaseUrl: {
      type: 'string',
      title: 'Clover Flex Connect Base Url',
      enum: ['https://api.clover.com', 'https://sandbox.dev.clover.com'],
    },
    cloverFlexMerchantId: {
      type: 'string',
      title: 'Clover Flex Merchant ID',
    },
    cloverFlexEcommerceToken: {
      type: 'string',
      title: 'Clover Flex Ecommerce Token',
    },
    cloverFlexAuthToken: {
      type: 'string',
      title: 'Clover Flex Auth Token',
    },
  },
  type: 'object',
};

export const CloverFlexForm: React.FC<{ data?: CloverFlexConfig; onSubmit(data: any) }> = ({ data, onSubmit }) => {
  const onSubmitForm = ({ formData }) => {
    onSubmit(formData);
  };

  return (
    <div className='w-100'>
      <Form formData={data} schema={cloverFlexConfigSchema} onSubmit={onSubmitForm} />
    </div>
  );
};
