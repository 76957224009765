import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { api } from '../../API';
import { PickupLocationForm } from './Form';

interface EditPickupLocationRouterParams {
  venueId: string;
  fulfillmentCenterId: string;
  id: string;
}

interface EditPickupLocationState {
  loading: boolean;
  pickupLocation: null | any;
}

type EditPickupLocationProps = RouteComponentProps<EditPickupLocationRouterParams>;

export class EditPickupLocation extends React.Component<EditPickupLocationProps, EditPickupLocationState> {
  constructor(props) {
    super(props);

    this.state = { loading: false, pickupLocation: null };
  }

  async componentDidMount() {
    const { venueId, fulfillmentCenterId, id } = this.props.match.params;
    const pickupLocation = await api.getPickupLocation(venueId, fulfillmentCenterId, id);

    this.setState({
      loading: false,
      pickupLocation,
    });
  }

  handleSubmit = async (data) => {
    const { venueId, fulfillmentCenterId, id } = this.props.match.params;
    await api.updatePickupLocation(venueId, fulfillmentCenterId, id, data);
    this.props.history.push(`/venues/${venueId}/fulfillment-centers/${fulfillmentCenterId}/pickup-locations/list`);
  };

  render() {
    if (this.state.loading || this.state.pickupLocation === null) {
      return <div>Loading...</div>;
    }

    return <PickupLocationForm pickupLocation={this.state.pickupLocation} onSubmit={this.handleSubmit} />;
  }
}
