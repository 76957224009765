import { format } from 'date-fns';
import * as React from 'react';
import { Button, Table } from 'reactstrap';

const OrderRow = ({ order, onClickRefund, onClickEdit, onClickViewOrder }) => {
  const onClick = () => onClickRefund(order);

  return (
    <tr>
      <td>{order.orderId}</td>
      <td>{format(new Date(order.createdAt), 'MMMM d, yyyy h:mm:ss a')}</td>
      <td>{order.orderNumber}</td>
      <td>${order.amount}</td>
      <td>${order.serviceFee}</td>
      <td>{order.tableNumber}</td>
      <td>${order.tax}</td>
      <td>${order.tip.amount}</td>
      <td>${order.total}</td>
      <td>
        <a href={`/users/edit/consumer/${order.user.id}`}>{order.user.name}</a>
      </td>
      <td>{order.status}</td>
      <td>
        <div className='mb-3'>
          <Button disabled={order.status === 'REFUNDED'} onClick={onClick}>
            Refund
          </Button>
        </div>
        <div>
          <Button onClick={() => onClickEdit(order)}>Edit Order</Button>
        </div>
        <div className='mt-3'>
          <Button onClick={() => onClickViewOrder(order)}>View Order</Button>
        </div>
      </td>
    </tr>
  );
};

export const OrdersTable = (props) => {
  if (props.loading) {
    return <div>Loading...</div>;
  } else {
    return (
      <Table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Date</th>
            <th>Number</th>
            <th>SubTotal</th>
            <th>Service Fee</th>
            <th>Table</th>
            <th>Tax</th>
            <th>Tip</th>
            <th>Total</th>
            <th>User</th>
            <th>Status</th>
            <th>Refund</th>
          </tr>
        </thead>
        <tbody>
          {props.orders.map((order) => (
            <OrderRow
              key={order.orderId}
              order={order}
              onClickRefund={props.onClickRefund}
              onClickEdit={props.onClickEdit}
              onClickViewOrder={props.onClickViewOrder}
            />
          ))}
        </tbody>
      </Table>
    );
  }
};
