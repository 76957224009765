import * as React from 'react';
import { api } from '../../API';
import { format } from 'date-fns';
import { SyncRecord } from '@getnoble/pos-service-v2';
import { Button } from 'reactstrap';

type DownloadLinKState = { type: 'idle' } | { type: 'loading' } | { type: 'link-ready'; link: string };

export const SyncRecordRow: React.FC<{ syncRecord: SyncRecord }> = ({ syncRecord }) => {
  const [downloadLinkState, setDownloadLinkState] = React.useState<DownloadLinKState>({
    type: 'idle',
  });
  const onClickDownload = React.useCallback(async () => {
    setDownloadLinkState({
      type: 'loading',
    });
    const linkResponse = await api.getSyncRecordNormalizedMenu(syncRecord.id);
    setDownloadLinkState({
      type: 'link-ready',
      link: linkResponse.objectUrl!,
    });
  }, [syncRecord]);

  const [rawDownloadLinkState, setRawDownloadLinkState] = React.useState<DownloadLinKState>({
    type: 'idle',
  });
  const onClickRawDownload = React.useCallback(async () => {
    setRawDownloadLinkState({
      type: 'loading',
    });
    const linkResponse = await api.getSyncRecordRawMenu(syncRecord.id);
    setRawDownloadLinkState({
      type: 'link-ready',
      link: linkResponse.objectUrl!,
    });
  }, [syncRecord]);

  return (
    <tr>
      <td>{format(syncRecord.startTime!, 'MMMM d, yyyy h:mm:ss a')}</td>
      <td>{syncRecord.endTime ? format(new Date(syncRecord.endTime), 'MMMM d, yyyy h:mm:ss a') : 'Processing'}</td>
      <td>
        <span className='text-danger'>{syncRecord.failureMessage}</span>
      </td>
      <td>{syncRecord.status}</td>
      <td>
        {downloadLinkState.type === 'idle' && <Button onClick={onClickDownload}>Get Menu</Button>}
        {downloadLinkState.type === 'loading' && <p>Loading...</p>}
        {downloadLinkState.type === 'link-ready' && (
          <Button download={`${syncRecord.startTime?.toISOString()}.json`} href={downloadLinkState.link}>
            Download Menu
          </Button>
        )}
      </td>
      <td>
        {rawDownloadLinkState.type === 'idle' && <Button onClick={onClickRawDownload}>Get POS Raw Menu</Button>}
        {rawDownloadLinkState.type === 'loading' && <p>Loading...</p>}
        {rawDownloadLinkState.type === 'link-ready' && (
          <Button download={`${syncRecord.startTime?.toISOString()}.json`} href={rawDownloadLinkState.link}>
            Download Menu
          </Button>
        )}
      </td>
    </tr>
  );
};
