import { Form } from '../../components';
import { pickupLocation as schema } from '@getnoble/noble-json-schemas';
import * as React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';

interface PickupLocationFormProps {
  pickupLocation?: any;
  onSubmit(data: any);
}

export class PickupLocationForm extends React.Component<PickupLocationFormProps, any> {
  render() {
    const onSubmit = (data) => this.props.onSubmit(data.formData);

    return (
      <Card>
        <CardHeader>Manage Center</CardHeader>
        <CardBody>
          <Form formData={this.props.pickupLocation} schema={schema} onSubmit={onSubmit} />
        </CardBody>
      </Card>
    );
  }
}
