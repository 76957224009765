export const byName = (o1, o2) => {
  const v1 = o1.name.toUpperCase(); // ignore upper and lowercase
  const v2 = o2.name.toUpperCase(); // ignore upper and lowercase
  if (v1 < v2) {
    return -1;
  }
  if (v1 > v2) {
    return 1;
  }

  // names must be equal
  return 0;
};

const getTimezone = () => {
  const timezoneOffsetMin = new Date().getTimezoneOffset();
  const offsetHours = parseInt(Math.abs(timezoneOffsetMin / 60).toString(), 10);
  const offsetMins = Math.abs(timezoneOffsetMin % 60);
  let timezoneStandard;

  let offsetHoursString = offsetHours.toString();
  let offsetMinsString = offsetMins.toString();

  if (offsetHours < 10) {
    offsetHoursString = '0' + offsetHours;
  }

  if (offsetMins < 10) {
    offsetMinsString = '0' + offsetMins;
  }

  // Add an opposite sign to the offset
  // If offset is 0, it means timezone is UTC
  if (timezoneOffsetMin < 0) {
    timezoneStandard = '+' + offsetHoursString + ':' + offsetMinsString;
  } else if (timezoneOffsetMin > 0) {
    timezoneStandard = '-' + offsetHoursString + ':' + offsetMinsString;
  } else if (timezoneOffsetMin === 0) {
    timezoneStandard = 'Z';
  }

  return timezoneStandard;
};

export const getDateTime = (date) => {
  let currentDate = date.getDate(),
    currentMonth = date.getMonth() + 1,
    currentHours = date.getHours(),
    currentMins = date.getMinutes(),
    currentSeconds = date.getSeconds();

  const currentYear = date.getFullYear();

  // Add 0 before date, month, hrs, mins or secs if they are less than 0
  currentDate = currentDate < 10 ? '0' + currentDate : currentDate;
  currentMonth = currentMonth < 10 ? '0' + currentMonth : currentMonth;
  currentHours = currentHours < 10 ? '0' + currentHours : currentHours;
  currentMins = currentMins < 10 ? '0' + currentMins : currentMins;
  currentSeconds = currentSeconds < 10 ? '0' + currentSeconds : currentSeconds;

  return (
    currentYear + '-' + currentMonth + '-' + currentDate + 'T' + currentHours + ':' + currentMins + ':' + currentSeconds
  );
};

export const toISOString = (date) => {
  return getDateTime(date) + getTimezone();
};
