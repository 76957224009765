import React from 'react';
import { Input } from 'reactstrap';

class EntitySelect extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = { loading: true };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount(): void {
    if (typeof window !== 'undefined') {
      this.props.options.load().then((results) => {
        this.setState({
          loading: false,
          options: this.props.options.process(results),
        });
      });
    }
  }

  onChange(event) {
    const type = this.props.options.type || 'number';
    const value = type === 'numeric' ? parseInt(event.target.value, 10) : event.target.value;

    if (value === 'Select') {
      return this.props.onChange(null);
    }

    this.props.onChange(value);
  }

  render() {
    if (this.state.loading) {
      return <div>Loading...</div>;
    }

    return (
      <Input defaultValue={this.props.value} type='select' name={this.props.name} onChange={this.onChange}>
        <option>Select</option>
        {this.state.options.map((option, key) => (
          <option key={key} value={option.value}>
            {option.name}
          </option>
        ))}
      </Input>
    );
  }
}

export default EntitySelect;
