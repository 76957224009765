import { format } from 'date-fns';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { api } from '../../API';
import { DataTable } from '../../components/DataTable';
import { paths } from '../../paths';

const dateFormat = 'L/d/yy hh:mm a';

export interface SessionRouteParams {
  venueId: string;
  fulfillmentCenterId: string;
}

export interface SessionState {
  sessions: any[];
  loading: boolean;
}

export class SessionsList extends React.PureComponent<RouteComponentProps<SessionRouteParams>, SessionState> {
  constructor(props) {
    super(props);

    this.state = { sessions: [], loading: true };
  }

  async componentDidMount() {
    const { venueId, fulfillmentCenterId } = this.props.match.params;
    const sessions = await api.getSessions(venueId, fulfillmentCenterId);
    this.setState({
      loading: false,
      sessions,
    });
  }

  togglePause = async (fulfillmentCenterId, acceptingOrders) => {
    this.setState({ loading: true });
    await api.updateSession(fulfillmentCenterId, acceptingOrders);

    const { venueId } = this.props.match.params;
    const sessions = await api.getSessions(venueId, fulfillmentCenterId);
    this.setState({
      loading: false,
      sessions,
    });
  };

  render() {
    if (this.state.loading) {
      return <div>Loading...</div>;
    } else {
      const { venueId, fulfillmentCenterId } = this.props.match.params;
      const { sessions } = this.state;

      return (
        <Card>
          <CardHeader>Sessions</CardHeader>
          <CardBody>
            <DataTable
              headers={['Open', 'Close', 'Orders', 'Actions']}
              rows={sessions.map((session) => [
                format(new Date(session.opened), dateFormat),
                session.closed && format(new Date(session.closed), dateFormat),
                session.orders,
                <>
                  <Button
                    key='action'
                    tag='a'
                    href={`/sessions/${venueId}/${fulfillmentCenterId}/${session.id}/last-report`}
                    color='success'
                  >
                    Final Report
                  </Button>
                  <Button
                    key='action'
                    tag='a'
                    href={paths.posOrders({ venueId, sessionId: `${session.id}` })}
                    color='success'
                    className='ms-1 me-1'
                  >
                    POS Order Status
                  </Button>
                  <Button
                    key='action'
                    onClick={() => this.togglePause(fulfillmentCenterId, !session.acceptingOrders)}
                    color='success'
                    className='me-3'
                  >
                    {session.acceptingOrders ? 'Pause' : 'Unpause'}
                  </Button>
                </>,
              ])}
            />
          </CardBody>
        </Card>
      );
    }
  }
}
