import * as React from 'react';
import { format } from 'date-fns';
import { Button, Table } from 'reactstrap';

const OrderItem = ({ item }) => {
  return (
    <li>
      {item.item.name} x {item.quantity}
      <ul>{item.options && item.options.map((option, index) => <li key={index}>{option.name}</li>)}</ul>
    </li>
  );
};

const formatLocation = (location) => {
  const parts = Object.keys(location).map((key) => {
    return `${key}: ${location[key]}`;
  });

  return parts.map((part) => {
    return <div key={part}>{part}</div>;
  });
};

const OrdersRow = ({ order, showActions, updateOrder, sendReminder, onClickSendReminder, rejectOrder }) => {
  const onClickSendReminderBinded = () => onClickSendReminder(order.orderId);

  let actions;
  if (showActions) {
    const onClickReady = () => updateOrder(order.orderId);
    const onClickReject = () => rejectOrder(order);
    actions = (
      <td>
        <Button tag='a' className='mb-3' onClick={onClickReady} color='primary'>
          Ready
        </Button>
        <br />
        <Button tag='a' onClick={onClickReject} color='danger'>
          Reject
        </Button>
      </td>
    );
  }

  return (
    <tr>
      {actions}
      <td>{order.orderId}</td>
      <td>{format(new Date(order.createdAt), 'MMMM d, yyyy h:mm:ss a')}</td>
      <td>{order.orderNumber}</td>
      <td>
        {order.scheduledTime ? format(new Date(order.scheduledTime), 'MMMM d, yyyy h:mm:ss a') : ''}
        <br />
        {order.timeSlot}
      </td>
      <td>{order.deliveryLocation && formatLocation(order.deliveryLocation)}</td>
      <td>${order.cartTotal}</td>
      <td>${order.tip.amount}</td>
      <td>${order.nobleConvenienceFee}</td>
      <td>${order.venueConvenienceFee}</td>
      <td>${order.total}</td>
      <td>{order.device}</td>
      <td>
        <a href={`/users/edit/consumer/${order.user.id}`}>{order.user.name}</a>
      </td>
      {sendReminder && (
        <td>
          <Button onClick={onClickSendReminderBinded}>Send Reminder</Button>
        </td>
      )}
      <td>
        <ul>
          {order.cart.map((item, index) => (
            <OrderItem key={index} item={item} />
          ))}
        </ul>
      </td>
    </tr>
  );
};

export const OrdersTable = (props) => {
  if (props.loading) {
    return <div>Loading...</div>;
  } else {
    let actionHeader;
    if (props.showActions) {
      actionHeader = <th>Actions</th>;
    }

    const orderRows = props.orders.map((order) => (
      <OrdersRow
        order={order}
        key={order.orderId}
        showActions={props.showActions}
        sendReminder={props.sendReminder}
        updateOrder={props.updateOrder}
        onClickSendReminder={props.onClickSendReminder}
        rejectOrder={props.rejectOrder}
      />
    ));

    return (
      <Table>
        <thead>
          <tr>
            {actionHeader}
            <th>ID</th>
            <th>Created At</th>
            <th>Number</th>
            <th>Scheduled Info</th>
            <th>Table</th>
            <th>SubTotal</th>
            <th>Tip</th>
            <th>Noble Convenience Fee</th>
            <th>Venue Convenience Fee</th>
            <th>Total</th>
            <th>Device</th>
            <th>User</th>
            {props.sendReminder && <th>Reminder</th>}
            <th>Cart</th>
          </tr>
        </thead>
        <tbody>{orderRows}</tbody>
      </Table>
    );
  }
};
