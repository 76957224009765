import * as React from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { api } from '../../API';
import { DataTable } from '../../components/DataTable';

export interface PromoCodesState {
  codes: any[];
  loading: boolean;
}

export class PromoCodesList extends React.Component<{}, PromoCodesState> {
  constructor(props) {
    super(props);
    this.state = { codes: [], loading: true };
  }

  async componentDidMount() {
    const codes = await api.getPromoCodes();
    this.setState({
      loading: false,
      codes,
    });
  }

  render() {
    if (this.state.loading) {
      return <div>Loading...</div>;
    } else {
      return (
        <div className='w-100'>
          <Card>
            <CardHeader>Promo Codes</CardHeader>
            <CardBody>
              <DataTable
                headers={['Code', 'Enabled', 'Amount', 'Actions']}
                rows={this.state.codes.map((code) => [
                  code.code,
                  code.enabled ? 'Yes' : 'No',
                  `$${code.amount}`,
                  <div key='actions'>
                    <Button tag='a' href={`/promo-codes/edit/${code.id}`} color='success'>
                      Edit
                    </Button>
                  </div>,
                ])}
              />
            </CardBody>
          </Card>
          <div className='text-center mt-3'>
            <Button tag='a' href='/promo-codes/add'>
              Add Code
            </Button>
          </div>
        </div>
      );
    }
  }
}
