import * as React from 'react';
import { Form } from '../../components';
import { api } from '../../API';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { JSONSchema7 } from 'json-schema';

const schema: JSONSchema7 = {
  type: 'object',
  properties: {
    start: {
      type: 'string',
      format: 'date',
    },
    end: {
      type: 'string',
      format: 'date',
    },
    skipHourCheck: {
      type: 'boolean',
    },
  },
};

export const PreorderReminderList: React.FC<{}> = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any>(null);

  const handleSubmit = React.useCallback(
    async ({ formData }) => {
      setLoading(true);
      const { start, end } = formData;
      const startDate = new Date(start);
      startDate.setHours(0, 0, 0, 0);

      const endDate = new Date(end);
      endDate.setHours(23, 59, 59, 999);

      const res = await api.fetchPreorderReminderList(startDate.toISOString(), endDate.toISOString());

      setData(res);
      setLoading(false);
    },
    [setLoading, setData]
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!data) {
    return (
      <Card className='w-100'>
        <CardHeader>Preorders</CardHeader>
        <CardBody>
          <Form schema={schema} onSubmit={handleSubmit} />
        </CardBody>
      </Card>
    );
  }

  return (
    <div className='w-100'>
      <Card className='w-100'>
        <CardHeader>Preorders</CardHeader>
        <CardBody>
          <pre>{JSON.stringify(data)}</pre>
        </CardBody>
      </Card>
    </div>
  );
};
