import * as React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Form,
  Input,
  Table,
  InputGroup,
  InputGroupText,
  Card,
  CardBody,
  CardHeader,
} from 'reactstrap';
import { api } from '../../API';
import { ConfirmModal } from '../../components/ConfirmModal';
import { RouteComponentProps } from 'react-router';

export class Search extends React.Component<RouteComponentProps<{ type: string }>, any> {
  constructor(props) {
    super(props);

    this.state = { loading: false, users: [], query: '', deleteUser: null, resetUrl: null };
  }

  submit = async (e) => {
    e.preventDefault();
    await this.search();
  };

  search = async () => {
    this.setState({ loading: true });
    const users = await api.searchUsers(this.state.query);
    this.setState({ loading: false, users });
  };

  onChangeQuery = (e) => {
    const { value: query } = e.target;
    this.setState({ query });
  };

  onClickDelete = (deleteUser) => {
    this.setState({ deleteUser });
  };

  onClickUnban = async (user) => {
    this.setState({ loading: true });
    await api.unbanUser(user.id);
    this.setState({ loading: false });
  };

  onClickEdit = (user) => {
    this.props.history.push(`/users/edit/${user.id}`);
  };

  onClickConfirm = async () => {
    await api.deleteUser(this.state.deleteUser.id);
    this.setState({ deleteUser: null }, async () => {
      await this.search();
    });
  };

  createPasswordResetLink = async (user) => {
    this.setState({ loading: true });
    const { url } = await api.createPasswordResetLink(user.id);
    this.setState({ loading: false, resetUrl: url });
  };

  cancelResetModal = () => {
    this.setState({ resetUrl: null });
  };

  render() {
    return (
      <div>
        <Card>
          <CardHeader>Search</CardHeader>
          <CardBody>
            <Form onSubmit={this.submit}>
              <InputGroup>
                <Input onChange={this.onChangeQuery} value={this.state.query} />
                <InputGroupText>Search</InputGroupText>
              </InputGroup>
            </Form>
          </CardBody>
        </Card>
        <Card className='mt-4'>
          <CardHeader>Results</CardHeader>
          <CardBody>
            <div>
              <Table>
                <thead>
                  <tr>
                    <td>ID</td>
                    <td>Email</td>
                    <td>Name</td>
                    <td>Actions</td>
                  </tr>
                </thead>
                <tbody>
                  {this.state.users.map((user) => (
                    <tr key={user.id}>
                      <td>{user.id}</td>
                      <td>{user.email}</td>
                      <td>
                        {user.firstName}
                        {user.lastName}
                      </td>
                      <td>
                        <Button className='me-2 mb-2' color='primary' onClick={() => this.onClickEdit(user)}>
                          Edit
                        </Button>
                        <Button color='danger' className='me-2' onClick={() => this.onClickUnban(user)}>
                          Un Ban
                        </Button>
                        <Button color='warning' className='me-2' onClick={() => this.createPasswordResetLink(user)}>
                          Create Reset Password Link
                        </Button>
                        <Button onClick={() => this.onClickDelete(user)}>Delete</Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
        <ConfirmModal
          open={!!this.state.deleteUser}
          toggle={() => this.onClickDelete(null)}
          title='Confirm Delete'
          buttons={[
            {
              onClick: this.onClickConfirm,
              title: 'Confirm',
              color: 'danger',
            },
          ]}
        >
          Confirm Deleting User:
          <pre>{JSON.stringify(this.state.deleteUser, null, 2)}</pre>
        </ConfirmModal>
        <Modal isOpen={this.state.resetUrl} toggle={this.cancelResetModal}>
          <ModalHeader toggle={this.cancelResetModal}>Reset Link</ModalHeader>
          <ModalBody>{this.state.resetUrl}</ModalBody>
        </Modal>
      </div>
    );
  }
}
