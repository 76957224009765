import React from 'react';
import JSONSchemaForm, { FormProps as JSONSchemaFormProps } from '@rjsf/core';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import EntitySelect from './EntitySelect';
import EntitySelectMultiple from './EntitySelectMultiple';
import { ImageUpload } from './ImageUpload';

function ArrayFieldItem({ element }) {
  let moveUpButton;
  if (element.hasMoveUp) {
    moveUpButton = (
      <Button color='success' onClick={element.onReorderClick(element.index, element.index - 1)}>
        Up
      </Button>
    );
  }

  let moveDownButton;
  if (element.hasMoveDown) {
    moveDownButton = (
      <Button color='success' onClick={element.onReorderClick(element.index, element.index + 1)}>
        Down
      </Button>
    );
  }

  return (
    <Card className='lg-vertical-margin'>
      <CardBody>
        <div>{element.children}</div>
        {moveDownButton}
        {moveUpButton}{' '}
        <Button color='danger' onClick={element.onDropIndexClick(element.index)}>
          Delete
        </Button>
      </CardBody>
    </Card>
  );
}

function ArrayFieldTemplate(props) {
  let content;
  if (props.items) {
    content = props.items.map((element) => <ArrayFieldItem key={element.index} element={element} />);
  }

  let addButton;
  if (props.canAdd) {
    addButton = (
      <div>
        <Button color='primary' onClick={props.onAddClick} type='button'>
          Add
        </Button>
      </div>
    );
  }

  return (
    <div className={props.className}>
      <h3>{props.title}</h3>
      {content}
      {addButton}
    </div>
  );
}

function HorizontalFieldTemplate(props) {
  const { id, classNames, label, help, required, description, errors, children } = props;

  if (id === 'root') {
    return (
      <div className={classNames}>
        <label htmlFor={id}>
          {label}
          {required ? '*' : null}
        </label>
        {description}
        {children}
        {errors}
        {help}
      </div>
    );
  }

  return (
    <div className={classNames}>
      <Row>
        <Col md={2}>
          <label htmlFor={id}>
            {label}
            {required ? '*' : null}
          </label>
        </Col>
        <Col>
          {description}
          {children}
          {errors}
          {help}
        </Col>
      </Row>
    </div>
  );
}

function renderFieldRow(prop) {
  const gridSize = prop.content.props.uiSchema.gridSize;
  const className = gridSize ? `col-md-${gridSize}` : 'col-md-12';

  return (
    <div className={className} key={prop.content.key}>
      {prop.content}
    </div>
  );
}

function ObjectFieldTemplate({ TitleField, properties, title, description }) {
  return (
    <div>
      <TitleField title={title} />
      <div className='row'>{properties.map(renderFieldRow)}</div>
      {description}
    </div>
  );
}

type FormProps<T> = JSONSchemaFormProps<T> & {
  horizontal?: boolean;
};

export const Form = <T,>(props: FormProps<T>) => {
  const { fields, widgets, ...otherProps } = props;

  const fullProps: FormProps<T> = {
    widgets: { ...widgets, upload: ImageUpload, entity: EntitySelect },
    fields: {
      entityMultiple: EntitySelectMultiple,
      ...fields,
    },
    ArrayFieldTemplate,
    ObjectFieldTemplate,
    showErrorList: false,
    ...otherProps,
  };

  if (props.horizontal) {
    fullProps.FieldTemplate = HorizontalFieldTemplate;
  }

  return <JSONSchemaForm {...fullProps} />;
};
