import { Form } from '../components';
import { venueInvite as schema } from '@getnoble/noble-json-schemas';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { useApi } from '../API';

export const VenueInvite: React.FC<RouteComponentProps> = (props) => {
  const { getMenus, getVenueOperators, sendVenueInvite, getRoles } = useApi();
  const handleSubmit = async ({ formData }) => {
    await sendVenueInvite(formData);
    props.history.push('/home');
  };

  const uiSchema: any = {};

  if (getVenueOperators) {
    uiSchema.operatorId = {
      'ui:widget': 'entity',
      'ui:options': {
        label: true,
        load: getVenueOperators,
        process: (operators) =>
          operators.map((operator) => ({
            value: operator.id,
            name: operator.name,
          })),
      },
    };

    uiSchema.menuId = {
      'ui:widget': 'entity',
      'ui:options': {
        label: true,
        load: getMenus,
        process: (menus) => menus.map((menu) => ({ value: menu.id, name: menu.name })),
      },
    };

    uiSchema.roleId = {
      'ui:widget': 'entity',
      'ui:options': {
        label: true,
        load: getRoles,
        type: 'string',
        process: (roles) => roles.map((role) => ({ value: role.id, name: role.name })),
      },
    };
  } else {
    uiSchema.operatorId = { 'ui:widget': 'hidden' };
  }

  return <Form schema={schema} onSubmit={handleSubmit} uiSchema={uiSchema} />;
};
