// import 'noble-components/dist/es/index.css';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Button, Card, CardBody, Form, FormGroup, Input, Label } from 'reactstrap';
import { EnvironmentHeader } from '../../components/EnvironmentHeader';
import { Auth } from '../../AuthService';

export class MFAInput extends React.Component<RouteComponentProps, any> {
  constructor(props) {
    super(props);

    this.state = { code: '', loading: false, error: null };
  }

  handleCodeChange = (event) => {
    this.setState({ code: event.target.value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({ loading: true });
    try {
      await Auth.loginWithMFACode(
        (this.props.location as any).state.mfaToken,
        this.state.code,
        (this.props.location as any).state.oobCode
      );

      this.props.history.push('/home');
    } catch (error) {
      this.setState({
        loading: false,
        error,
      });
    }
  };

  render() {
    return (
      <div className='login-container'>
        <EnvironmentHeader />
        <Card className='centered-block login-box'>
          <Form onSubmit={this.handleSubmit} method='post'>
            <CardBody>
              <p>{this.state.error && this.state.error.message}</p>
              <p>A code has been sent to your phone.</p>
              <FormGroup>
                <Label className='sr-only' for='username'>
                  Code
                </Label>
                <Input
                  placeholder='Code'
                  name='username'
                  id='username'
                  onChange={this.handleCodeChange}
                  className='login-input'
                />
              </FormGroup>
              <Button block={true} color='primary' type='submit' disabled={this.state.loading}>
                Login
              </Button>
            </CardBody>
          </Form>
        </Card>
        <div className='login-version'>{process.env.RAZZLE_NOBLE_COMMIT_HASH}</div>
      </div>
    );
  }
}
