import * as React from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { api } from '../../API';
import { DataTable } from '../../components/DataTable';

export class RejectReasonsList extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = { rejectReasons: [], loading: true };
  }

  fetch = async () => {
    const rejectReasons = await api.getRejectReasons();
    this.setState({
      loading: false,
      rejectReasons,
    });
  };

  componentDidMount() {
    this.fetch();
  }

  render() {
    if (this.state.loading) {
      return <div>Loading...</div>;
    } else {
      const { rejectReasons } = this.state;

      return (
        <Card className='w-100'>
          <CardHeader>Reject Reasons</CardHeader>
          <CardBody>
            <DataTable
              headers={['Reason', 'Actions']}
              rows={rejectReasons.map((reason) => [
                reason.reason,
                <Button key='btn' tag='a' href={`/reject-reasons/edit/${reason.id}`} color='success'>
                  Edit
                </Button>,
              ])}
            />
            <div className='text-center'>
              <Button tag='a' href={`/reject-reasons/add`} color='success'>
                Add
              </Button>
            </div>
          </CardBody>
        </Card>
      );
    }
  }
}
