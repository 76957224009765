import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Card,
  CardBody,
  CardHeader,
  Table,
  Form,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import { api } from '../../API';
import { OrdersTable } from '../../components/OrdersTable';

interface FinalReportRouteParams {
  venueId: string;
  fulfillmentCenterId: string;
  sessionId: string;
}

const getTotalFromRow = (row) => {
  return (
    row.unitPrice +
    row.options.reduce((sum, option) => {
      return sum + option.unitPrice;
    }, 0)
  );
};

const ItemRow = ({ row }) => {
  return (
    <tr>
      <td>{row.name}</td>
      <td>{row.options.map((option) => option.name).join(', ')}</td>
      <td>${row.unitPrice}</td>
      <td>
        <ul>
          {row.options.map((option, index) => (
            <li key={index}>
              {option.name} - {option.unitPrice}
            </li>
          ))}
        </ul>
      </td>
      <td>{row.quantity}</td>
      <td>${getTotalFromRow(row)}</td>
    </tr>
  );
};

const ItemsTable = ({ categories }) => {
  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Options</th>
          <th>Unit Price</th>
          <th>Option Price</th>
          <th>Quantity</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        {categories.map((category) => category.items.map((row, index) => <ItemRow key={index} row={row} />))}
      </tbody>
    </Table>
  );
};

const SummaryRow = ({ row }) => {
  return (
    <div className='row' key={row.title}>
      <dt className='col-sm-3 text-right'>{row.title}</dt>
      <dd className='col-sm-9'>${row.value}</dd>
    </div>
  );
};

const Sumamry = ({ report }) => {
  const rows = [
    { title: 'Venue Total (No Fees)', value: report.total },
    { title: 'Subtotal', value: report.totalSubtotal },
    { title: 'Total Venue Convenience Fee', value: report.venueConvenienceFee },
    { title: 'Total Fees', value: report.totalFees },
    { title: 'Total Promo', value: report.totalPromo },
    { title: 'Total Tax', value: report.totalTax },
    { title: 'Total Sales Tax', value: report.totalSalesTax },
    { title: 'Total Tip', value: report.totalTip },
  ];

  return (
    <dl>
      {rows.map((row, index) => (
        <SummaryRow key={index} row={row} />
      ))}
    </dl>
  );
};

const SendSessionEmailModalContent = ({ onSubmit }) => {
  const [emails, setEmails] = React.useState<string>();
  const onChangeEmails = React.useCallback(
    (e) => {
      setEmails(e.target.value);
    },
    [setEmails]
  );

  const onSubmitWrapped = React.useCallback(
    (e) => {
      e.preventDefault();
      onSubmit(emails?.split(', '));
    },
    [onSubmit, emails]
  );

  return (
    <ModalBody>
      <Form onSubmit={onSubmitWrapped}>
        <FormGroup>
          <Label>Emails (separate by comma):</Label>
          <Input value={emails} onChange={onChangeEmails} />
        </FormGroup>
        <Button type='submit' color='success'>
          Send
        </Button>
      </Form>
    </ModalBody>
  );
};

export class FinalReport extends React.Component<RouteComponentProps<FinalReportRouteParams>, any> {
  constructor(props) {
    super(props);

    this.state = {
      exportUrl: null,
      session: null,
      orders: null,
      loading: true,
      emailModal: false,
    };
  }

  componentDidMount() {
    this.fetch();
  }

  async fetch() {
    const { venueId, sessionId } = this.props.match.params;
    const [session, orders] = await Promise.all([
      api.getLastReportForSession(venueId, sessionId),
      api.getOrdersForSession(venueId, sessionId),
    ]);

    this.setState({ session, orders, loading: false });
  }

  getCSVData = () => {
    const optionStr = (options) => {
      if (!options || !options.length) {
        return '';
      }

      return `(${options.map((opt) => opt.name)})`;
    };

    const orders = this.state.orders.map((original) => {
      const deliveryLocation = !original.deliveryLocation ? {} : original.deliveryLocation;

      return {
        'Order Id': original.orderId,
        'Order Number': original.orderNumber,
        Subtotal: original.cartTotal,
        'Noble Convenience Fee': original.nobleConvenienceFee,
        'Venue Convenience Fee': original.venueConvenienceFee,
        'Table Number': original.tableNumber,
        Tax: original.tax,
        'Sales Tax': original.salesTax,
        Tip: original.tip.amount,
        ...deliveryLocation,
        'Venue Total': Number(original.total) - Number(original.nobleConvenienceFee),
        Total: original.total,
        User: original.user.name,
        Cart: original.cart.reduce((accum, row) => {
          return `${accum} ${row.item.name} ${optionStr(row.options)}`;
        }, ''),
      };
    });

    return JSON.stringify(orders);
  };

  exportCSV = async () => {
    const body = this.getCSVData();

    this.setState({ loading: true });
    const file = await fetch('/csv', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    });

    const blob = await file.blob();

    const exportUrl = window.URL.createObjectURL(blob);

    this.setState({ loading: false, exportUrl });
  };

  openSessionModal = () => {
    this.setState({
      emailModal: true,
    });
  };

  toggleEmailModal = () => {
    this.setState({
      emailModal: false,
    });
  };

  onSubmitEmails = async (emails) => {
    this.setState({ emailModal: false, loading: true });

    const { venueId, fulfillmentCenterId, sessionId } = this.props.match.params;

    await api.sendSessionEmail(venueId, fulfillmentCenterId, sessionId, emails);

    this.setState({ loading: false });
  };

  render() {
    if (this.state.loading) {
      return <div>Loading...</div>;
    }

    let exportButton;
    if (this.state.loading) {
      exportButton = 'Loading';
    } else if (this.state.exportUrl) {
      exportButton = (
        <Button href={this.state.exportUrl} tag='a' color='primary'>
          Download
        </Button>
      );
    } else {
      exportButton = (
        <Button onClick={this.exportCSV} tag='a' color='success'>
          Export
        </Button>
      );
    }

    return (
      <div className='w-100'>
        <Card className='mb-4'>
          <CardHeader>Summary</CardHeader>
          <CardBody>
            <Button onClick={this.openSessionModal} tag='a' className='me-2' color='success'>
              Send Session Email
            </Button>
            {exportButton}
            <Sumamry report={this.state.session} />
          </CardBody>
        </Card>
        <Card className='mb-4'>
          <CardHeader>Items</CardHeader>
          <CardBody>
            <ItemsTable categories={this.state.session.categories} />
          </CardBody>
        </Card>
        <Card className='mb-4'>
          <CardHeader>Orders</CardHeader>
          <CardBody>
            <OrdersTable orders={this.state.orders} title='Orders' />
          </CardBody>
        </Card>
        <Card className='mb-4'>
          <CardHeader>Raw</CardHeader>
          <CardBody>
            <pre>{JSON.stringify(this.state.session, null, 2)}</pre>
          </CardBody>
        </Card>
        <Modal isOpen={!!this.state.emailModal} toggle={this.toggleEmailModal}>
          <ModalHeader>Send Session Emails</ModalHeader>
          {this.state.emailModal && <SendSessionEmailModalContent onSubmit={this.onSubmitEmails} />}
        </Modal>
      </div>
    );
  }
}
